let initialValue = ""

export function getTitle() {
  return initialValue
}

export function setTitle(params) {
  initialValue = params
}
export function currFormat(num)  {
    let a = Number(num);
    return a.toFixed().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  }
