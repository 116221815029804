import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  auth
} from "../../firebase/firebase";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess } from "../../appRedux/actions/Auth";
import { post } from "util/HttpRequest";
import { setUser } from "util/DataUser";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) => {
  return await post({ url: `/auth/login`, data: { username: email, password: password } })
}

const signOutRequest = async () =>
  await auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      yield put(userSignUpSuccess(signUpUser.user.uid));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    console.log("USERR",signInUser.data.data)
    if (!signInUser.data.token) {
      yield put(showAuthMessage(signInUser.data.message));
    } else {
      yield put(userSignInSuccess(signInUser.data.data));
      localStorage.setItem('secret', signInUser.data.token);
      localStorage.setItem('token', JSON.stringify(signInUser.data.data));
      setUser(signInUser.data.data)
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (typeof signOutUser === "undefined") {
      localStorage.clear();
      window.location.reload()
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}


export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
  fork(createUserAccount),
  fork(signOutUser)]);
}
