import React from "react";
import {Route, Switch} from "react-router-dom";
import Parameter from "pages/Config/Parameter/Index";
import Users from "pages/Config/Users/Index";
import Finance from "pages/Config/Finance/Index"
import UploadRBB from "pages/Config/UploadRbb/Index"
const UserKomisaris = ({match}) => (
  <Switch>
    <Route path={`${match.url}/parameter`} component={Parameter}/>
    <Route path={`${match.url}/users`} component={Users}/>
    <Route path={`${match.url}/finance`} component={Finance}/>
    <Route path={`${match.url}/upload-rbb`} component={UploadRBB}/>
  </Switch>
);

export default UserKomisaris;
