import {
  Alert,
  Button,
  Card,
  DatePicker,
  Input,
  Modal,
  Result,
  Row,
  Space,
  Tabs,
  Tooltip,
} from "antd";
import Col from "antd/es/grid/col";
import { paramRbb, paramData } from "dummy/parameter";
import React, { useState, useEffect } from "react";
import KinerjaKeuangan from "./KinerjaKeuangan";
import Komitmen from "./Komitmen";
import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import { getUser } from "util/DataUser";
import moment from "moment";
import { setTitle } from "util/functions";
import { GET_GLOBAL_PARAMS } from "API/Params";
import { GET_MESSAGE } from "API/API";
// const locale = moment.locale("id")

export default function UserPinca() {
  let dateNow = new Date();
  dateNow.setDate(dateNow.getDate() - 1);
  const [key, setKey] = useState("1");
  const [date, setDate] = useState(dateNow);
  const [openDate, setOpenDate] = useState(false);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isCommit, setIsCommit] = useState(false);
  const [commit, setCommit] = useState([]);

  const dateFormat = "YYYY-MM-DD";
  setTitle(`Lap. ${getUser().kantorCabang}`);
  useEffect(() => {
    getParamFas();
    checkKomitment();
  }, []);
  // console.log("date", date);
  const checkKomitment = async () => {
    try {
      //Get User Commitment ALL Date
      const { data } = await GET_MESSAGE(
        `?sort=-createdAt&query[kantor]=${getUser().kantor.kode}`
      );
      //Filter By User Komitmen
      let filterUser = data.docs.filter((e) => e.user.level === "Pimpinan Cabang");
      // console.log("getUser", getUser());
      //Find All Days in a Weeks Ago In Array
      let currentDate = moment().subtract(1, 'w');
      let weekStart = currentDate.clone().startOf("week");
      let days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
      }
      //Find Komitmen User In A week
      const findKomitmen = filterUser.some((r) =>
        days.includes(moment(r.tglLaporan).format("YYYY-MM-DD"))
      );

      if (findKomitmen) {
        setIsCommit(true);
        setDisabled(false);
      } else {
        setDate(weekStart);
        setIsCommit(false);
        setDisabled(true);
        setVisible(true);
      }
    } catch (e) {}
  };
  const getParamFas = async () => {
    try {
      const {
        data: { data },
      } = await GET_GLOBAL_PARAMS();
      const komitmen = data.komitmen.value;
      setCommit(komitmen);
      // console.log("res",komitmen)
    } catch (e) {}
  };

  const changeDate = (_date, dateString) => {
    if (dateString !== "") {
      setDate(dateString);
      setOpenDate(false);
    } else {
      setOpenDate(true);
    }
  };

  const changeTab = (key) => {
    console.log(key);
    setKey(key);
    // console.log("User",user.kantor)
  };
  const items = [
    {
      label: (
        <span>
          <BarChartOutlined /> Kinerja Keuangan{" "}
        </span>
      ),
      key: "1",
      children: (
        <KinerjaKeuangan
          data={paramData}
          paramRbb={paramRbb}
          changeTab={changeTab}
          kodeKantor={getUser()?.kantor?.kode}
          date={date}
          kodeCabang={getUser()?.kantor?.kodeCabang}
        />
      ),
    },
    {
      label: (
        <span>
          <AuditOutlined /> Komitmen & Tanggapan
        </span>
      ),
      key: "2",
      children: (
        <Komitmen date={date} commit={commit} checkKomitment={checkKomitment} />
      ),
    },
  ];
  const text = !isCommit
    ? "Filter tanggal laporan bisa diakses setelah mengisi komitmen."
    : "Filter Tanggal Laporan";

  return (
    <div>
      {!isCommit && (
        <Alert
          message="Tidak ditemukan komitmen"
          description="Komitmen Pimpinan Cabang pada Laporan minggu lalu belum diselesaikan, Silahkan mengisi komitmen pada laporan!"
          type="warning"
          showIcon
          closable
        />
      )}
      <Card className="gx-card" style={{ marginBottom: 5 }}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <Space>
              <ClusterOutlined className="gx-mb-3" />
              <p> Kantor Cabang</p>
            </Space>
            <Input disabled={true} value={getUser().kantorCabang} />
          </Col>
          <Col span={12}>
            <Tooltip
              placement="topLeft"
              title={text}
              color={isCommit ? "green" : "red"}
            >
              <Space>
                <CalendarOutlined className="gx-mb-3" />
                <p>Pilih Tanggal</p>
              </Space>

              <DatePicker
                style={{ width: "100%" }}
                format={dateFormat}
                onChange={changeDate}
                value={moment(date, dateFormat)}
                open={openDate}
                onClick={() => setOpenDate(true)}
                disabled={disabled}
              />
            </Tooltip>
          </Col>
        </Row>
      </Card>
      <Tabs
        defaultActiveKey="1"
        onChange={changeTab}
        items={items}
        className="gx-p-2"
        activeKey={key}
      />
      <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
        <Result
          status="403"
          title="Akses Laporan Dibatasi"
          subTitle={`Anda hanya bisa mengakses laporan pada ${moment(
            date
          ).format(
            "DD MMMM YYYY"
          )} Silahkan mengisi komitmen laporan pada minggu lalu.`}
          extra={
            <Button
              type="primary"
              onClick={() => {
                changeTab("2");
                setVisible(false);
              }}
            >
              Isi Komitmen Sekarang
            </Button>
          }
        />
      </Modal>
    </div>
  );
}
