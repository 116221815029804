import moment from "moment";

export const setRumus = (param, date) => {
   const month = moment(moment(date).format("YYYY/MM/DD")).format('M');
    //Get Variable
    let find290 = param.find(e => e.code === "290") //Aset
    let find260 = param.find(e => e.code === "260") 
    let find370 = param.find(e => e.code === "370")
    let find403 = param.find(e => e.code === "403")
    let find130 = param.find(e => e.code === "130")
    let find331 = param.find(e => e.code === "331")
    let find332 = param.find(e => e.code === "332")
    let find350 = param.find(e => e.code === "350")
    let find401 = param.find(e => e.code === "401")
    let find501 = param.find(e => e.code === "501")
    let find502 = param.find(e => e.code === "502")
    let find503 = param.find(e => e.code === "503")
    let findNPF = param.find(e => e.code === "R003")
    let fdr1 = {
        name: "- FDR-1 (Pemby/DPK)",
        code: "R001",
        sum: {
            raw: 0
        }
    }
    let fdr2 = {
        name: "- FDR-2 (Pemby/(DPK+ABP+RAK)",
        code: "R002",
        sum: {
            raw: 0
        }
    }
    let produktifitas = {
        name: "- Produktifitas Pembiayaan",
        code: "R004",
        sum: {
            raw: 0
        }
    }
    let totalBiaya = { 
        name: "- Biaya Dana/Total Dana",
        code: "R005",
        sum: {
            raw: 0
        }
    }
    //Get Value Sum
    let rakPasiva = find370.sum.raw
    let pendapatanRAK = find403.sum.raw
    let rakAktiva = find260.sum.raw
    let asetValue = find290.sum.raw
    let biayaRAK = find503.sum.raw

    //Set Rumus
    let asetValue_ = rakAktiva > rakPasiva ? asetValue - rakPasiva : rakAktiva < rakPasiva ? asetValue - rakAktiva : asetValue - rakAktiva;
    // console.log("asetValue", asetValue_)
    let rakAktiva_ = rakAktiva < rakPasiva ? 0 : rakAktiva - rakPasiva
    let rakPasiva_ = rakPasiva > rakAktiva ? rakPasiva - rakAktiva : 0
    let pendapatanRAK_ = pendapatanRAK < biayaRAK ? 0 : pendapatanRAK > biayaRAK ? pendapatanRAK - biayaRAK : ""
    let biayaRAK_ = pendapatanRAK < biayaRAK ? biayaRAK - pendapatanRAK : pendapatanRAK > biayaRAK ? 0 : ""


    let sumBagiHasil = find501.sum.raw + find502.sum.raw + find503.sum.raw 
    let sumDPK = find331.sum.raw + find332.sum.raw
    let sumDPKTabungan = sumDPK + find350.sum.raw + rakPasiva_
    let resFDR = find130.sum.raw / sumDPK * 100
    let resFDR2 = find130.sum.raw / sumDPKTabungan * 100
    let resProduktifitas = find401.sum.raw / Number(month) / find130.sum.raw * 100
    let resBiayaDana  = find501.sum.raw / sumDPK * 100
    let resNPF = findNPF.sum.raw / find130.sum.raw * 100

    // Value Changed
    find290.sum.raw = asetValue_
    find260.sum.raw = rakAktiva_
    find370.sum.raw = rakPasiva_
    find403.sum.raw = pendapatanRAK_
    find503.sum.raw = biayaRAK_
    findNPF.sum.raw = Number(resNPF.toFixed(2))
    fdr1.sum.raw = Number(resFDR.toFixed(2))
    fdr2.sum.raw = Number(resFDR2.toFixed(2))
    produktifitas.sum.raw = Number(resProduktifitas.toFixed(2))
    totalBiaya.sum.raw = Number(resBiayaDana.toFixed(2))
    param.push(fdr1)
    param.push(fdr2)
    param.push(produktifitas)
    param.push(totalBiaya)

    return param
}