import { Button, Input, Result, Table } from 'antd'
import { GET_RBB } from 'API/Params'
import React, { useEffect, useState } from 'react'
import { getUser } from 'util/DataUser'
import { currFormat } from 'util/functions'
import { notif } from 'util/notification'

export default function ListData({ year , setVisible}) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        getRbb()
    }, [year])

    const getRbb = async () => {
        setLoading(true)
        let paramKantorCabang = getUser().level === "Admin" ? "00" : getUser().kantor?.kode
        try{
            let query = `?query={"kantor":"${paramKantorCabang}","tahun":"${year}"}`
            const {data} = await GET_RBB(query)
            setData(data.docs[0]?.data)
            // console.log("dataRbb", data)
        }catch(e){
            console.log("erroe",e)
            notif("error", "Gagal!", "Gagal mendapatkan data RBB")

        }finally{
            setLoading(false)
        }
      
    }
    // console.log("dataaa",data)
    const col = [
        { title: "Kode", dataIndex: "code", width: 70, fixed  : "left" },
        { title: "Uraian", dataIndex: "name", width: 200, fixed  : "left", zIndex: 200 },
        { title: `Jan-${year}`, dataIndex: `Jan`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Feb-${year}`, dataIndex: `Feb`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Mar-${year}`, dataIndex: `Mar`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Apr-${year}`, dataIndex: `Apr`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Mei-${year}`, dataIndex: `May`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Jun-${year}`, dataIndex: `Jun`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Jul-${year}`, dataIndex: `Jul`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Aug-${year}`, dataIndex: `Aug`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Sep-${year}`, dataIndex: `Sep`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Okt-${year}`, dataIndex: `Oct`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Nov-${year}`, dataIndex: `Nov`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
        { title: `Des-${year}`, dataIndex: `Desc`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={Number(text).toFixed(2)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} />, width: 150 },
    ]
    return (
        <div>
            {data ? <Table dataSource={data} columns={col} size="small" pagination={false} scroll={{ y: 1080 }} bordered rowKey={"code"} loading={loading} /> : <Result title={`RBB tahun ${year} belum diunggah, silahkan unggah file RBB`} status="404"  extra={<Button type="primary" onClick={() => setVisible(true)}>Unggah RBB</Button>}/>}
            
        </div>
    )
}
