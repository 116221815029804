// import "./styles.css";
import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart
} from "recharts";

const data = [
  {
    name: "2018",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "2019",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "2020",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "2021",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  
];

export default function PihakKetiga() {
  return (
    <div style={{ width: '100%', height: 300 }}>
    <ResponsiveContainer>
    <ComposedChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" name=""/>
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="pv" stackId="a" name="Tab. Mudharabah" fill="#5995DF" />
      <Bar dataKey="amt" stackId="a" fill="red" name="Simp. Wadiah" />
      <Bar dataKey="uv" stackId="a" fill="#A7C761"name="Dep. Mudharabah"/>
    </ComposedChart>
    </ResponsiveContainer>
    </div>
  );
}
