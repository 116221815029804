import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import configureStore, { history } from "./appRedux/store";
import "./firebase/firebase";
import App from "./containers/App/index";
// import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { GlobalDebug } from "util/remove-consoles";
require("dotenv").config();
// console.log("ENV",process.env)

const store = configureStore(/* provide initial state if any */);

const NextApp = () => {
  React.useEffect(() => {
    process.env.NODE_ENV === "production" && GlobalDebug(false);
  }, []);

  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </ConnectedRouter>
    </Provider>
  );
};

export default NextApp;
