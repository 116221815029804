import { FolderFilled, FolderOpenFilled } from '@ant-design/icons'
import { Card, Tabs } from 'antd'
import React, {useState} from 'react'
import GroupKomisaris from './GroupKomisaris'
import GroupLaporan from './GroupLaporan'
import KantorCabang from './KantorCabang'
import LaporanKinerja from './LaporanKinerja'
import LaporanKomisaris from './LaporanKomisaris'
import UserLevel from './UserLevel'
import Finance from '../Finance/Index'
import { useMediaQuery } from 'react-responsive'
import Neraca from "../Neraca/Index"
import { setTitle } from 'util/functions'

export const Kinerja = () => {
    const [key, setKey] = useState("1")
    const icon = (a) => {
        if(a === key) return <FolderOpenFilled/>
        return <FolderFilled/>
    }
    const items = [
        { label: <span>{icon("1")}Komponen</span>, key: '1', children: <LaporanKinerja/> }, 
        { label: <span>{icon("2")}Group</span>, key: '2', children: <GroupLaporan/> }, 
    ]
    return (
        <div>
            <Tabs items={items} onChange={(e) => setKey(e)} activeKey={key} animated={true} className="gx-p-2"/>
        </div>
    )
}
export const KinerjaDewan = () => {
    const [key, setKey] = useState("1")
    const icon = (a) => {
        if(a === key) return <FolderOpenFilled/>
        return <FolderFilled/>
    }
    const items = [
        { label: <span>{icon("1")}Komponen</span>, key: '1', children: <LaporanKomisaris/> }, 
        { label: <span>{icon("2")}Group</span>, key: '2', children: <GroupKomisaris/> }, 
    ]
    return (
        <div>
            <Tabs items={items} onChange={(e) => setKey(e)} activeKey={key} animated={true} className="gx-p-2" />
        </div>
    )
}
export const COA = () => {
    const [key, setKey] = useState("1")
    const icon = (a) => {
        if(a === key) return <FolderOpenFilled/>
        return <FolderFilled/>
    }
    const items = [
        { label: <span>{icon("1")}COA</span>, key: '1', children: <Finance/> }, 
        { label: <span>{icon("2")}Neraca</span>, key: '2', children: <Neraca/> }, 
    ]
    return (
        <div>
            <Tabs items={items} onChange={(e) => setKey(e)} activeKey={key} animated={true} className="gx-p-2" />
        </div>
    )
}

export default function Index() {
    const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 })
    const [key, setKey] = useState("1")
    setTitle("Parameter")
    const icon = (a) => {
        if(a === key) return <FolderOpenFilled className="gx-mr-2"/>
        return <FolderFilled className="gx-mr-2"/>
    }
    const items = [
        { label: <span>{icon("1")}Kantor Cabang</span>, key: '1', children: <KantorCabang/> }, 
        { label: <span>{icon("2")}Laporan Kinerja Bulanan</span>, key: '2', children: <Kinerja/> }, 
        { label: <span>{icon("3")}Laporan Dewan Komisaris</span>, key: '3', children: <KinerjaDewan/> }, 
        { label: <span>{icon("4")}Parameter COA</span>, key: '4', children: <COA/> }, 
        { label: <span>{icon("5")}User Level</span>, key: '5', children: <UserLevel/> }, 
    ]
    return (
        <div>
            <Card className="gx-card">
            <Tabs tabBarExtraContent items={items} onChange={(e) => setKey(e)} activeKey={key} animated={true} className="gx-p-1" tabPosition={isDesktopOrLaptop ? 'top' : 'top'}/>
            </Card>
        </div>
    )
}