import { PlusCircleFilled } from "@ant-design/icons";
import { Table, Button, Spin } from "antd";
import { GET_LAPORAN_FINANCE } from "API/API";
import {
  GET_PARAM_GROUP_KOMPONEN,
  GET_PARAM_KOMPONEN,
  GET_RBB,
} from "API/Params";
import _ from "lodash";
import moment from "moment";
import { setRumus } from "pages/UserDireksi/function";
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { currFormat } from "util/functions";
import { notif } from "util/notification";

export default function KinerjaKeuangan({
  kodeKantor,
  date,
  kodeCabang,
  changeTab,
}) {
  // console.log("oke", date)
  const [laporanData, setLaporanData] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  // const [component, setComponent] = useState([])
  const [group, setGroup] = useState([]);
  const [warning, setWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [colLastMonth, setColLastMonth] = useState("");
  const [colLastYear, setColLastYear] = useState("");
  const [colRbb, setColRbb] = useState("");
  const [colRealisasi, setColRealisasi] = useState("");
  useEffect(() => {
    getData();
    setPeriode();
    changeTab("1");
    // checkKomitment();
  }, [kodeKantor, date]);

  const setPeriode = () => {
    let lastMonth = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    let colLastMonth = moment(moment(lastMonth, "YYYY-MM-DD")).format(
      "DD MMMM YYYY"
    );
    let lastYear = moment(date)
      .subtract(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD");
    let colLastYear = moment(moment(lastYear, "YYYY-MM-DD")).format(
      "MMMM YYYY"
    );
    setColLastYear(colLastYear);
    setColLastMonth(colLastMonth);
    setColRbb(moment(moment(date, "YYYY-MM-DD")).format("MMMM YYYY"));
    setColRealisasi(moment(moment(date, "YYYY-MM-DD")).format("DD MMMM YYYY"));
  };
  const getData = async () => {
    //Cek Komitmen Pada Minggu Ini
    //Jika ada komitmen dari pinca untuk minggu ini dia bisa preview,
    //Jika Ada Maka Tampilkan Data
    //Jika Tidak Ada Laporan Tidak Bisa Ditampilkan

    setLoading(true);
    try {
      const { data } = await GET_PARAM_KOMPONEN("?page=1&limit=1000");
      const { data: group } = await GET_PARAM_GROUP_KOMPONEN(
        "?page=1&limit=1000"
      );
      group.docs.map((e) => {
        e.nourut =
          e.name === "Main"
            ? 1
            : e.name === "Pendapatan"
            ? 2
            : e.name === "Biaya"
            ? 3
            : e.name === "Rasio"
            ? 4
            : 0;
      });
      group.docs.sort((a, b) => a.nourut - b.nourut);
      setGroup(group.docs);
      getValueData(data.docs);
    } catch (e) {
      notif("error", "Gagal1!", "Gagal dalam mengambil data!");
    } finally {
    }
  };

  const handleData = (comp, datas) => {
    setLoading(true);
    let param = comp;
    let data = datas;
    var merged = _.merge(_.keyBy(param, "name"), _.keyBy(data, "name"));
    var values = _.values(merged);
    const filterUser = "PINCA";
    const result = values.filter((item) => {
      return item.visibility.indexOf(filterUser) >= 0;
    });
    result.sort((a, b) => a.nourut - b.nourut);
    result.map((e) => {
      e.capaianRp = Number(e.realisasiValue) - Number(e.rbbValue);
      e.capaianPersent = (Number(e.realisasiValue) / Number(e.rbbValue)) * 100;
      e.rpGrowth = Number(e.realisasiValue) - Number(e.lastYearValue);
      e.percentGrowth = (Number(e.rpGrowth) / Number(e.lastYearValue)) * 100;
    });
    let findPendapatan = result.filter((e) => e.group.name === "Pendapatan");
    let findBiaya = result.filter((e) => e.group.name === "Biaya");

    // console.log("findPendapatan", findPendapatan)
    let totalPendapatanLM = 0;
    let totalPendapatanLY = 0;
    let totalPendapatan = 0;
    findPendapatan.forEach(
      ({ realisasiValue, lastMonthValue, lastYearValue }) => {
        totalPendapatan += Number(realisasiValue);
        totalPendapatanLM += Number(lastMonthValue);
        totalPendapatanLY += Number(lastYearValue);
      }
    );
    // console.log("findBiaya", findBiaya)
    let totalBiayaLM = 0;
    let totalBiayaLY = 0;
    let totalBiaya = 0;
    findBiaya.forEach(({ realisasiValue, lastMonthValue, lastYearValue }) => {
      totalBiaya += Number(realisasiValue);
      totalBiayaLM += Number(lastMonthValue);
      totalBiayaLY += Number(lastYearValue);
    });

    let totalLabaLY = totalPendapatanLY - totalBiayaLY;
    let totalLabaLM = totalPendapatanLM - totalBiayaLM;
    let totalLaba = totalPendapatan - totalBiaya;

    let findLaba = result.find((e) => e.code === "700");
    findLaba.realisasiValue = totalLaba;
    findLaba.lastMonthValue = totalLabaLM;
    findLaba.lastYearValue = totalLabaLY;
    setLaporanData(result);
    setLoading(false);
  };

  const getValueData = async (component) => {
    setLoading(true);
    let lastMonth = moment(date)
      .subtract(1, "months")
      .endOf("month")
      .format("YYYY-MM-DD");
    let colLastMonth = moment(moment(lastMonth, "YYYY-MM-DD")).format(
      "DD MMMM YYYY"
    );
    let lastYear = moment(date)
      .subtract(1, "year")
      .endOf("year")
      .format("YYYY-MM-DD");
    let colLastYear = moment(moment(lastYear, "YYYY-MM-DD")).format(
      "MMMM YYYY"
    );
    let yearRbb = moment(date, "YYYY-MM-DD").year();
    var currMonthName = moment(date).format("MMM");
    currMonthName = currMonthName === "Dec" ? "Desc" : currMonthName;
    // console.log("cur", currMonthName);
    setColLastYear(colLastYear);
    setColLastMonth(colLastMonth);
    setColRbb(moment(moment(date, "YYYY-MM-DD")).format("MMMM YYYY"));
    try {
      const { data: realisasi } = await GET_LAPORAN_FINANCE(
        `?kodeCabang=${kodeCabang}&date=${date}&hide=nosbbs,neraca`
      );
      const { data: lastMonth_ } = await GET_LAPORAN_FINANCE(
        `?kodeCabang=${kodeCabang}&date=${lastMonth}&hide=nosbbs,neraca`
      );
      const { data: lastYear_ } = await GET_LAPORAN_FINANCE(
        `?kodeCabang=${kodeCabang}&date=${lastYear}&hide=nosbbs,neraca`
      );
      const { data: Rbb } = await GET_RBB(
        `?query={"kantor":"${kodeKantor}","tahun":"${yearRbb}"}`
      );
      let rbbVal = Rbb.docs[0]?.data;

      let data = [];
      let r = setRumus(realisasi.data, date);
      let lm = setRumus(lastMonth_.data, lastMonth);
      let ly = setRumus(lastYear_.data, lastYear);

      component.map((e) => {
        let realisasiVal = r.filter((item) => item.code === e.code);
        let lastMonthVal = lm.filter((item) => item.code === e.code);
        let lastYearVal = ly.filter((item) => item.code === e.code);
        let rbbVals = rbbVal?.filter((item) => item.name === e.name);
        // console.log("rbbVal", rbbVals);
        let dataname = {
          name: e.name,
          code: e.code,
          realisasiValue: 0,
          lastMonthValue: 0,
          lastYearValue: 0,
          rbbValue: 0,
        };
        realisasiVal.forEach((val) => {
          dataname.realisasiValue = val.sum?.raw;
        });
        if (typeof rbbVals !== "undefined") {
          rbbVals.forEach((val) => {
            dataname.rbbValue = val[currMonthName] ? val[currMonthName] : 0;
          });
        }

        lastMonthVal.forEach((val) => {
          dataname.lastMonthValue = val.sum?.raw;
        });
        lastYearVal.forEach((val) => {
          dataname.lastYearValue = val.sum?.raw;
        });
        data.push(dataname);
      });
      await handleData(component, data);
    } catch (e) {
      notif("error", "Gagal!", "Gagal dalam mengambil data!");
    } finally {
      setLoading(false);
    }
  };

  const handleCapaianRp = (data) => {
    const total = Number(data.realisasiValue) - Number(data.rbbValue);
    return currFormat(total);
  };
  const handleCapaianPersent = (data) => {
    const total = data.capaianPersent;
    return (
      <div className={total < 0 ? "gx-text-danger" : "gx-text-success"}>
        {total ? `${total.toFixed(2)} %` : ""}{" "}
      </div>
    );
  };

  const columns = [
    {
      title: <div className="gx-text-center">Uraian</div>,
      dataIndex: "name",
      width: !isMobile ? 240 : 120,
      key: "id",
      fixed: "left",
    },
    {
      title: <div className="gx-text-center">{colLastYear}</div>,
      render: (record) =>
        record.group.name !== "Rasio"
          ? currFormat(record.lastYearValue)
          : record.lastYearValue + "%",
      width: 145,
      key: "id",
      align: "right",
      footer: (data) => {
        return (
          <div>
            Summary:{" "}
            {data.reduce((sum, record) => sum + record.lastYearValue, 0)}
          </div>
        );
      },
    },
    {
      title: <div className="gx-text-center">{colLastMonth}</div>,
      render: (record) =>
        record.group.name !== "Rasio"
          ? currFormat(record.lastMonthValue)
          : record.lastMonthValue + "%",
      width: 145,
      key: "id",
      align: "right",
    },
    {
      title: <div className="gx-text-center">RBB</div>,
      children: [
        {
          title: <div className="gx-text-center">{colRbb}</div>,
          render: (record) =>
            record.group.name !== "Rasio"
              ? currFormat(record.rbbValue)
              : record.rbbValue.toFixed(3) + "%",
          width: 145,
          align: "right",
        },
      ],
    },
    {
      title: "REALISASI",
      children: [
        {
          title: <div className="gx-text-center">{colRealisasi}</div>,
          render: (record) =>
            record.group.name !== "Rasio"
              ? currFormat(record.realisasiValue)
              : record.realisasiValue + "%",
          width: 145,
          align: "right",
        },
      ],
    },

    {
      title: "Capaian",
      children: [
        {
          title: <div className="gx-text-center">(Rp. )</div>,
          render: (record) =>
            record.group.name !== "Rasio" ? handleCapaianRp(record) : "",
          width: 145,
          align: "right",
        },
        {
          title: <div className="gx-text-center">%</div>,
          render: (record) =>
            record.group.name !== "Rasio" ? handleCapaianPersent(record) : "",
          width: 100,
          align: "right",
        },
      ],
    },
    {
      title: `Pertumbuhan dari ${colLastYear}`,
      children: [
        {
          title: <div className="gx-text-center">(Rp. )</div>,
          render: (record) =>
            record.group.name !== "Rasio" ? (
              record.rpGrowth > 0 ? (
                currFormat(record.rpGrowth)
              ) : (
                <span className="gx-text-danger">
                  ({currFormat(record.rpGrowth)})
                </span>
              )
            ) : (
              ""
            ),
          width: 145,
          align: "right",
        },
        {
          title: <div className="gx-text-center">%</div>,
          render: (record) =>
            record.group.name !== "Rasio" ? (
              <p
                className={
                  record.percentGrowth <= 0
                    ? "gx-text-danger"
                    : "gx-text-success"
                }
              >
                {" "}
                {record.percentGrowth
                  ? record.percentGrowth.toFixed(2) + "%"
                  : ""}
              </p>
            ) : (
              ""
            ),
          width: 100,
          align: "right",
        },
      ],
    },
  ];
  const totalData = (pageData, flag) => {
    // console.log("pageda",pageData, flag)
    let rbbVal = 0;
    let realisasiVal = 0;
    let lastMonthVal = 0;
    let lastYearVal = 0;
    pageData.forEach(
      ({ rbbValue, realisasiValue, lastMonthValue, lastYearValue }) => {
        rbbVal += Number(rbbValue);
        realisasiVal += Number(realisasiValue);
        lastMonthVal += Number(lastMonthValue);
        lastYearVal += Number(lastYearValue);
      }
    );
    return (
      <>
        <Table.Summary.Row className="gx-bg-light ">
          <Table.Summary.Cell className="gx-text-dark">
            Jumlah {flag}
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className="gx-text-dark gx-text-right">
              {currFormat(lastYearVal)}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className="gx-text-dark gx-text-right">
              {currFormat(lastMonthVal)}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className="gx-text-dark gx-text-right">
              {currFormat(rbbVal)}
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell>
            <div className="gx-text-dark gx-text-right">
              {currFormat(realisasiVal)}
            </div>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </>
    );
  };
  let groupby = group.filter(
    (e) => e.name !== "Main Komisaris" && e.name !== "Saldo dan Pos Utama"
  );
  // console.log("grupby",groupby);
  return (
    <div className="gx-mt-1">
      <>
        {groupby.map((e, i) => {
          return (
            <>
              <div className="kinerja-keuangan">
                <div className={""} key={i}>
                  {e.name !== "Main" ? (
                    <p className="gx-bg-light gx-text-dark gx-p-2 gx-mt-2">
                      {e.name}
                    </p>
                  ) : (
                    ""
                  )}
                  <Table
                    dataSource={laporanData.filter(
                      (i) => i.group.name === e.name
                    )}
                    columns={columns}
                    rowKey="id"
                    size="middle"
                    pagination={false}
                    scroll={{ x: 1270 }}
                    bordered
                    loading={{
                      indicator: (
                        <div>
                          <Spin />
                        </div>
                      ),
                      spinning: loading,
                      tip: "Mohon menunggu, Sedang Mengambil Komponen Data...",
                    }}
                    tip={"Sedang Mengambil Data.."}
                    summary={
                      e.calculated
                        ? (pageData) => totalData(pageData, e.name)
                        : null
                    }
                  />
                </div>
              </div>
            </>
          );
        })}
        <Button
          icon={<PlusCircleFilled />}
          onClick={() => changeTab("2")}
          className="gx-btn-block gx-mt-3"
          type="primary"
        >
          TAMBAH KOMITMEN
        </Button>
      </>
    </div>
  );
}
