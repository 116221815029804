// import "./styles.css";
import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart
} from "recharts";

const data = [
  {
    name: "2018",
    uv: 50,
    pv: 10,
    amt: 33,
    xy : 77
  },
  {
    name: "2019",
    uv: 40,
    pv: 5,
    amt: 23,
    xy : 50
  },
  {
    name: "2020",
    uv: 55,
    pv: 12,
    amt: 20,
    xy : 30
  },
  {
    name: "2021",
    uv: 60,
    pv: 13,
    amt: 20,
    xy : 44
  },
  
];

export default function KomposisiTabungan() {
  return (
    <div style={{ width: '100%', height: 300 }}>
    <ResponsiveContainer>
    <ComposedChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" name=""/>
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="pv" stackId="a" name="1 s/d 10jt" fill="red" />
      <Bar dataKey="amt" stackId="a" fill="#5995DF" name="10 s/d 25 jt" />
      <Bar dataKey="uv" stackId="a" fill="#A7C761"name="25 s/d 50jt"/>
      <Bar dataKey="xy" stackId="a" fill="#8568A8"name="> 50jt"/>
    </ComposedChart>
    </ResponsiveContainer>
    </div>
  );
}
