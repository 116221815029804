import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Button, Modal, Space, Table, Form, Input, Divider, Row, Col} from 'antd'
import { paramKantorCabang, paramUserLevel } from 'dummy/parameter'
import React, { useState } from 'react'

const FormItem = Form.Item
const label = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};


export default function UserLevel() {
    const [visible, setVisible] = useState(false)
    const [datas, setDatas] = useState({})
    const [titleModal, setTitleModal] = useState("Cabang Baru")
    const [form] = Form.useForm();
    // let titleModal = "Cabang Baru"

    const newCabang = () => {
        form.resetFields()
        setVisible(true)
        setTitleModal("Level Baru")
        setDatas({})
    }
    const confirm = (data) => {
        Modal.confirm({
            title: 'Konfirmasi',
            icon: <ExclamationCircleOutlined />,
            content: `Apakah anda yakin untuk menghapus ${data.name} ? Data akan terhapus secara permanen.`,
            okText: 'Hapus',
            cancelText: 'Tutup',
        });
    };


    const handleActiion = (record) => {
        return <Space><EditOutlined className="gx-text-primary" style={{ cursor: 'pointer' }} onClick={() => { setDatas(record); setVisible(true); setTitleModal("Edit Level User") }} /> <DeleteOutlined className="gx-text-danger" onClick={() => confirm(record)} /></Space>
    }
    const columns = [
        {
            title: "Level User",
            dataIndex: "name",
            width: 200
        },
      
        {
            title: "Aksi",
            width: 100,
            render: (record) => handleActiion(record),
            align: "right"
        }
    ]
    const onFinish = (value) => {
        console.log("finish", value)
    }


    return (
        <>
        <Row gutter={[8, 8]}>
            <Col lg={18} md={18} sm={12} xs={12}>
            <Input.Search placeholder="Cari User Levl .."/>
            </Col>
            <Col  lg={6} md={6} sm={12} xs={12}>
            <Button className="gx-btn-block" type="primary" icon={<PlusCircleFilled />} onClick={() => newCabang()}>Level Baru</Button>
            </Col>
        </Row>
        <div className="gx-module-box-column">
            <Table dataSource={paramUserLevel} columns={columns} rowKey="id" rowClassName={(_record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'} />
            <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
                <h3>{titleModal}</h3>
                <Divider />
                <Form
                    {...label}
                    name="control-hooks"
                    form={form}
                    onFinish={onFinish}
                    fields={[
                        {
                            name: ["name"],
                            value: datas.name
                        },
                    ]}>
                    <FormItem name="name" rules={[{ required: true }]} label="Nama Level" >
                        <Input />
                    </FormItem>
                    <div className="gx-text-right">
                        <Button htmlType="submit" type="primary">Simpan</Button>
                    </div>
                </Form>
            </Modal>
        </div>
        </>
    )
}
