import { PlusCircleFilled } from '@ant-design/icons'
import { Card , Row, Col, Input, Button, Modal, Skeleton, AutoComplete } from 'antd'
import Search from 'antd/lib/input/Search'
import { GET_USERS } from 'API/Users'
import { get, map } from 'lodash'
// import { usersData } from 'dummy/users'
import React, { useState, useEffect }from 'react'
import { setTitle } from 'util/functions'
// import { notif } from 'util/notification'
import ListUsers from './ListUsers'
import UserDetail from './UserDetail'

export default function Index() {
    setTitle("Users")
    const [visible, setVisible] = useState(false)
    const [usersData, setUsersData] = useState([])
    const [detail, setDetail] = useState({})
    const [loading, setLoading] = useState(false)
    const [titleModal, setTitleModal] = useState("Tambah User Baru")
    const [secondData, setSecondData] = useState([])
    const [searchValue, setSearchValue] = useState("")
    useEffect(() => {
       getUsers()
    }, [])
    const getUsers = async () => {
        try{
            setLoading(true)
            const {data} = await GET_USERS()
            setUsersData(data.data)
            setSecondData(data.data)
            // notif("success", "okee")
        }catch(e){

        }finally{
            setLoading(false)
        } 
    }
    const handleSearch = e => {
        setSearchValue(e)
      };
    return (
        <>
        <Card className="gx-card">
            <Row gutter={[6, 6]}>
                <Col lg={18} md={18} sm={12} xs={12}>
                <AutoComplete
                style={{
                    width: "100%",
                }}
                onSearch={handleSearch}
            >
                <Input.Search  placeholder="Cari Nama, Level, Kantor Cabang..." enterButton />
            </AutoComplete>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Button className="gx-btn-block" type="primary" icon={<PlusCircleFilled />} onClick={() => {setVisible(true); setDetail({}); setTitleModal("Tambah User Baru")}} disabled={true}>User Baru</Button>
                </Col>
            </Row>
            </Card>
            <Skeleton loading={loading} animated={true} rows="5">
            <ListUsers data={usersData} setVisible={setVisible} setDetail={setDetail} setTitleModal={setTitleModal} searchValue={searchValue}/>
            </Skeleton>
           
            <Modal visible={visible} onCancel={() => setVisible(false)} footer={null} style={{top : 5}} title={titleModal}>
                <UserDetail data={detail}/>
            </Modal>
            </>
       
    )
}
