import { Card, DatePicker, Row, Col, Button, Modal, Upload, message, Spin } from 'antd'
import { GET_PARAM_KOMPONEN, UPLOAD_RBB } from 'API/Params'
import React, { useState, useEffect } from 'react'
import { setTitle } from 'util/functions'
import ListData from './ListData'
import * as XLSX from 'xlsx'
import { FileExcelFilled } from '@ant-design/icons'
import { notif } from 'util/notification'
import { getUser } from 'util/DataUser'
import Preview from './Preview'
import moment from 'moment'
const { Dragger } = Upload;

const yearFormat = 'YYYY';

export default function Index() {
  const d = new Date()
  let yearInit = d.getFullYear();
  const [component, setComponent] = useState([])
  const [visible, setVisible] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openDate, setOpenDate] = useState(false)
  const [year, setYear] = useState(yearInit)
  const [file, setFile] = useState("")
  setTitle("Upload RBB")
  useEffect(() => {
    getData()
  }, [])
  const getData = async () => {
    try {
      const { data } = await GET_PARAM_KOMPONEN("?page=1&limit=1000")

      data.docs.map((e) => {
        e.group.no = e.group.name === "Main" ? 1 : e.group.name === "Pendapatan" ? 2 : e.group.name === "Biaya" ? 3 : e.group.name === "Rasio" ? 4 : 0
      })
      data.docs.sort((a, b) => a.nourut - b.nourut)
      // console.log("Data1", data.docs)
      // data.docs.sort((a, b) => a.nourut - b.nourut)
      // console.log("Data2", data.docs)
      setComponent(data.docs)
    } catch (e) { }
  }

  const handleYear = (_date, dateString) => {
    if (dateString) {
      setYear(dateString)
      setOpenDate(false)
    } else {
      setOpenDate(true)
    }


  }
  const props = {
    name: 'target',
    multiple: false,
    maxCount: 1,

    onChange(e) {

      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      try {
        reader.onload = (e) => {
          const bstr = e.target.result;
          // console.log('oke', bstr);
          const wb = XLSX.read(bstr, {
            type: rABS ? 'binary' : 'array',
            bookVBA: true,
          });
          // /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          // worksheet["!cols"] = [ { wch: 10 } ];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws);
          /* Update state */
          // console.log(data)
          setComponent(data)
          setVisible(false)
          setShowTable(true)
        };
        if (rABS) {
          reader.readAsBinaryString(e.file.originFileObj);
        } else {
          reader.readAsArrayBuffer(e.file.originFileObj);
        }
        setFile(e.file.originFileObj)
        message.success("upload success!");
      } catch (e) {
        message.error("Upload gagal!")

      }
    },
    onDrop(e) {
      // console.log("oke", e)
      // console.log('Dropped files', e.dataTransfer.files);
    },
  };
  const downloadExcel = () => {
    const rows = component.map(row => ({
      name: row.name,
      code: row.code,
      group: row.group.name,
      Jan: "",
      Feb: "",
      Mar: "",
      Apr: "",
      May: "",
      Jun: "",
      Jul: "",
      Aug: "",
      Sep: "",
      Oct: "",
      Nov: "",
      Desc: "",
      // birthday: row.bio.birthday
    }));
    rows.filter(item => item.group !== "Saldo dan Pos Utama")


    // console.log("rows",rows)
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();
    const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
    worksheet["!cols"] = [{ wch: max_width }];
    XLSX.utils.book_append_sheet(workbook, worksheet, `Template Upload RBB Thn-${year}`);
    XLSX.writeFile(workbook, `Template Upload RBB Thn-${year}.xlsx`);
  }
  const handleSend = async () => {
    setLoading(true)
    try {
      let kantor = getUser().level === "Admin" ? "00" : getUser().kantor.kode
      let formData = new FormData();
      formData.append('file', file)
      formData.append('kantor', kantor);
      formData.append('tahun', year)
      // console.log("formData",formData);
      await UPLOAD_RBB(formData);
      setShowTable(false)
      notif("success", "Sukses!", "Data RBB berhasil di Upload!")
    } catch (e) {
      notif("error", "Gagal", "Tejadi Kesalahan saat upload RBB!")
    } finally {
      setLoading(false)
    }
  }
  return (
    <div>
      {!showTable && <Card className="gx-card">
        <Row gutter={[8, 8]}>
          <Col lg={12} md={12} sm={12} xs={12}>
            <DatePicker picker='year' style={{ width: '100%', marginBottom: 5 }} onChange={handleYear} value={moment(year, 'YYYY')} format={yearFormat} open={openDate} onClick={() => setOpenDate(true)} />
          </Col>
          <Col lg={12} md={12} sm={12} xs={12}>
            <Button type="" className="gx-btn gx-btn-success gx-btn-block" onClick={() => setVisible(true)} style={{ marginBottom: -10 }}>Import Excel</Button>
          </Col>
        </Row>
      </Card>}
      <Modal open={visible} onCancel={() => setVisible(false)} title="Upload RBB dari Excel" footer={null}>
        <p>1. Silahkan Pilih Tahun Upload RBB</p>
        <DatePicker picker='year' style={{ width: '100%', marginBottom: 5 }} onChange={handleYear} value={moment(year, 'YYYY')} format={yearFormat} open={openDate} onClick={() => setOpenDate(true)} />
        <p>2. Download Template RBB</p>
        <Button type="" className="gx-btn gx-btn-success gx-btn-block" disabled={year ? false : true} onClick={() => downloadExcel()} icon={<FileExcelFilled />}>Download Template</Button>
        <p>3. Isikan data RBB sesuai template</p>
        <p>4. Upload File RBB</p>
        {year && <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <FileExcelFilled style={{ color: 'green' }} />
          </p>
          <p className="ant-upload-text">Click or drag file excel to this area to upload</p>
          <p className="ant-upload-hint">
            Pastikan file rbb sudah benar dan berformat .xls .xlsx
          </p>
        </Dragger>}
        <p>5. Preview dan Simpan Data</p>
      </Modal>
      {showTable ? <Spin spinning={loading}><Preview component={component} year={year} handleSend={() => handleSend()} /></Spin> : <ListData component={component} year={year? year : ""} setVisible={(r) => setVisible(r)}/>}
    </div>
  )
}
