import { AutoComplete, Input, notification, Select, Row, Col } from 'antd'
import { GET_PARAM_FINANCE } from 'API/Params'
import React, { useState, useEffect } from 'react'
import qs from "qs";
import ListData from './ListData'
export default function Index() {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [search, setSearch] = useState("")
    const [sort, setSort] = useState("")
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10
        },
        query: ""
    });
    const [query, setQuery] = useState("")


    useEffect(() => {
        const newQuery = qs.stringify({ page: tableParams.pagination.current, limit: tableParams.pagination.pageSize, query: search, sort: sort === "" ? "" : sort });
        getData(newQuery)
        setQuery(newQuery)
    }, [JSON.stringify(tableParams), search, sort])

    const getData = async (param) => {
        setLoading(true)

        try {
            const { data } = await GET_PARAM_FINANCE(`?${param}`)
            data.docs.forEach((e) => {
                e.code_ = e.code.length > 0 ? e.code.map(i => i._id) : []
            })
            setData(data.docs)
            setTableParams({
                ...tableParams,
                pagination: {
                    current: data.page,
                    pageSize: data.limit,
                    total: data.totalDocs,
                }
            });
        } catch (e) {
            notification.error("error", "Failed!", "Gagal Mengambil Data!")
        } finally {
            setLoading(false)
        }
    }
    const handleSearch = (value) => {
        setSearch(value);
        setTableParams({ pagination: { current: 1 } })
    }
    const handleTableChange = (pagination, filters, sorter) => {
        setTableParams({
            pagination,
            filters,
            ...sorter
        });
    }
    const handleSort = (val) => {
        if (val === "kodeloc1") { setSort({ 'kodeloc': 'asc' }) }
        if (val === "kodeloc2") { setSort({ 'kodeloc': 'desc' }) }
        if (val === "nosbb1") { setSort({ 'nosbb': 'asc' }) }
        if (val === "nosbb2") { setSort({ 'nosbb': 'desc' }) }
        if (val === "") { setSort("") }
    }
    return (
        <div>
            <Row>
                <Col xl={16} lg={16} md={24} sm={24} xs={24}>
                    <AutoComplete
                        style={{
                            width: "100%",
                        }}
                        onSearch={handleSearch}
                    >
                        <Input.Search placeholder="Cari Keterangan, NO. SBB, Kode Loc.." enterButton />
                    </AutoComplete>
                </Col>
                <Col xl={8} lg={8} md={24} sm={24} xs={24}>
                    <Select style={{ width: '100%' }} placeholder="Urutkan (Sort)" onChange={handleSort}>
                        <Select.Option value="kodeloc1">Kode Loc (ASCEND)</Select.Option>
                        <Select.Option value="kodeloc2">Kode Loc (DESCEND)</Select.Option>
                        <Select.Option value="nosbb1">No. SBB (ASCEND)</Select.Option>
                        <Select.Option value="nosbb2">No. SBB (DESCEND)</Select.Option>
                        <Select.Option value="">-</Select.Option>
                    </Select>
                </Col>
            </Row>

            <ListData data={data} loading={loading} refreshData={() => getData(query)} handleTableChange={handleTableChange} tableParams={tableParams} />
        </div>
    )
}
