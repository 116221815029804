import React from "react";
import { Route, Switch } from "react-router-dom";
// import Main from "./main/index";
import UserPinca from "./user-pinca/index"
import UserDireksi from "./user-direksi";
import UserKomisaris from "./user-komisaris";
import Dashboard from "../pages/Dashboard/Index"
import Config from "./config"
import { Result } from "antd";

const App = ({ match, menu }) => {
  let pathNow = window.location.pathname
  let holder = [];
  const checkChildren = (list) => {
    list.forEach(item => {
      if (item.children) {
        checkChildren(item.children);
      } else {
        holder = [...holder, item]
      }
    })
  }
  checkChildren(menu);
  const checkPath = () => {
    let checkPath = holder.filter(item => item.path === pathNow)
    if (checkPath.length > 0) {
      return true
    } else {
      return false
    }
  };
  if (checkPath()) {
    return (
      <div className="gx-main-content-wrapper">
        <Switch>
          {/* <Route path={`${match.url}main`} component={Main} /> */}
          <Route path={`${match.url}dashboard`} component={Dashboard} />
          <Route path={`${match.url}config`} component={Config} />
          <Route path={`${match.url}user-pinca`} component={UserPinca} />
          <Route path={`${match.url}user-direksi`} component={UserDireksi} />
          <Route path={`${match.url}user-komisaris`} component={UserKomisaris} />
        </Switch>
      </div>
    )
  } else {
    return (
      <div style={{width: '100%'}}>
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
        />
      </div>
    )
  }

}



export default App;
