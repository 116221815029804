import a from 'axios'
import {getAccessHeader} from "./DataUser";

const {REACT_APP_API} = process.env
export const base_url = REACT_APP_API

const mergeRecursive = (obj1, obj2) => {
  if (!obj1) return obj2
  for (let p in obj2) {
    try {
      // Property in destination object set; update its value.
      if ( obj2[p].constructor===Object ) {
        obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);

      } else {
        obj1[p] = obj2[p];

      }

    } catch(e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];
    }
  }
  return obj1;
}
export function get(resourceHttpRequest) {
  const { url, config } = resourceHttpRequest
  return a.get( base_url + url, mergeRecursive(getAccessHeader(), config))
}

export function post(resourceHttpRequest) {
  const { url, config, data } = resourceHttpRequest
  return a.post(base_url + url, data, mergeRecursive(getAccessHeader(), config))
}

export function put(resourceHttpRequest) {
  const { url, config, data } = resourceHttpRequest
  return a.put(base_url + url, data, mergeRecursive(getAccessHeader(), config))
}

export function delete_request(resourceHttpRequest) {
  const { url, config } = resourceHttpRequest
  return a.delete(base_url + url,  mergeRecursive(getAccessHeader(), config))
}
