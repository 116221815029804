import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { useSelector } from "react-redux";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import { setTitle } from "util/functions";



const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed , listMenu}) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };


  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];


  const handleLabel = (path, name, icon) => {
    if (!path) {
      return <span popupClassName={getNavStyleSubMenuClass(navStyle)}><i className={icon } />
      <span>{name}</span>
      </span>
    } else {
      return <Link to={path} onClick={() => {setTitle(name)}}><i className={icon} />
        <span >{name}</span>
      </Link>
    }

  }
  const getNavStyleSubMenuClass = (navStyle) => {
    console.log("NAV", navStyle);
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };
  const nameGroup = (label, key) => {
    return <li className="" key={key} >
      <span >  {label}</span>
    </li>
  }
  listMenu.forEach((e) => {
    if (e.type === "group") {
      e.label = nameGroup(e.label, e.key)
      if(e.children){
        if(!e.children[0].path){
          e.children[0].className = ""
        }
      }
    }
    if (e.children) {
      e.children.map((i) => {
        if (i.children) {
          i.children.map((f) => {
            f.label = handleLabel(f.path, f.name, f.ico)
          })
        }
        i.label = handleLabel(i.path, i.name, i.ico)
      })
    } else {
      e.label = handleLabel(e.path, e.name, e.ico)
    }
  })
  // console.log("Menus,", Menus)


  return (
    <>
      <SidebarLogo sidebarCollapsed={sidebarCollapsed} setSidebarCollapsed={setSidebarCollapsed} />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notification`}>
        </div>
        <div className={`gx-sidebar-notifications `}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
            mode="inline" items={listMenu} />
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);

