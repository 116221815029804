/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  PlusCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  GET_MESSAGE,
  POST_MESSAGE,
} from "API/API";
import {
  Alert,
  Button,
  Card,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Timeline,
} from "antd";
import Col from "antd/es/grid/col";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { getUser } from "util/DataUser";
import { notif } from "util/notification";

export default function Komitmen({ date, commit, checkKomitment }) {
  const [loading, setLoading] = useState(false);
  // const [disabled, setDisabled] = useState(true)
  const [isEdit, setIsEdit] = useState(false);
  const [visible, setVisible] = useState(false);
  const [colRealisasi, setColRealisasi] = useState("");
  const [komitmens, setKomitmens] = useState([
    { commit: "", description: "", date: colRealisasi },
  ]);
  const [listKomitmen, setListKomitmen] = useState([]);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const getMessage = useCallback(async () => {
    try {
      const { data } = await GET_MESSAGE(
        `?sort=-createdAt&query[kantor]=${getUser().kantor.kode}`
      );
      setListKomitmen(data.docs);
      // console.log("pk", data.docs);
    } catch (e) {
      Alert.error("Gagal mendapatkan data komitmen");
    }
  }, []);

  useEffect(() => {
    setColRealisasi(moment(moment(date, "YYYY-MM-DD")).format("DD MMMM YYYY"));
    getMessage();
  }, [date, getMessage]);

  const handleCloseModal = useCallback(() => {
    console.log("handleCloseModal");

    setVisible(false);
    setIsEdit(false);
    setKomitmens([{ commit: "", description: "", date: colRealisasi }]);
  }, [colRealisasi]);

  const appendField = useCallback(() => {
    const state = {
      commit: "",
      description: "",
      date: colRealisasi,
    };
    setKomitmens((prev) => [...prev, state]);
  }, [colRealisasi]);

  const deleteKomitmen = useCallback(
    (index) => setKomitmens((prev) => prev.filter((_, i) => i !== index)),
    []
  );

  const handleChange = useCallback(
    (data, index, name) =>
      setKomitmens((prev) =>
        prev.map((item, i) => (i === index ? { ...item, [name]: data } : item))
      ),
    []
  );

  const sendMessage = useCallback(
    async (query) => {
      setLoading(true);

      try {
        if (isEdit) {
          let data = {
            commit: query.commit,
            description: query.description,
          };
          await EDIT_MESSAGE(query.id, data);
        } else {
          await POST_MESSAGE(query);
        }
        notif("success", "Sukses!", "Data berhasil disimpan.");
        handleCloseModal();
        checkKomitment();
        getMessage();
      } catch (e) {
        notif("error", "Error!", "Data gagal disimpan!");
      } finally {
        setLoading(false);
      }
    },
    [checkKomitment, getMessage, handleCloseModal, isEdit]
  );

  const handleSave = useCallback(() => {
    const list = komitmens.filter(
      (e) => e.commit !== "" && e.description !== ""
    );

    list.length > 0
      ? Promise.all(
          list.map((e) =>
            sendMessage({
              ...e,
              tglLaporan: moment(date, "YYYY-MM-DD").format("YYYY-MM-DD"),
              kantor: getUser().kantor.kode,
            })
          )
        )
      : alert("Isi Komitmen dan uraian dengan Lengkap!");
  }, [komitmens, date, sendMessage]);

  const editMessage = useCallback((e) => {
    setIsEdit(true);
    setVisible(true);
    setKomitmens([e]);
  }, []);

  const deleteItem = useCallback(
    async (data) => {
      try {
        DELETE_MESSAGE(data.id);
        notif("success", "Sukses!", "Komitmen Berhasil Terhapus!");
        getMessage();
        checkKomitment();
      } catch (e) {
        notif("error", "Error!", "Komitmen Gagal Terhapus!");
      }
    },
    [checkKomitment, getMessage]
  );

  const deleteMessage = useCallback(
    (data) => {
      Modal.confirm({
        title: "Konfirmasi",
        icon: <ExclamationCircleOutlined />,
        content: `Apakah anda yakin untuk menghapus ? Komitmen akan terhapus secara permanen.`,
        okText: "Hapus",
        cancelText: "Tutup",
        onOk: () => deleteItem(data),
      });
    },
    [deleteItem]
  );

  const getExtra = useCallback(
    (e) => {
      if (e.user.name === getUser().name) {
        return (
          <>
            <a href="#" className="gx-mr-2" onClick={() => editMessage(e)}>
              Edit
            </a>
            <a
              href="#"
              className="gx-text-danger"
              onClick={() => deleteMessage(e)}
            >
              Hapus
            </a>
          </>
        );
      }
    },
    [deleteMessage, editMessage]
  );

  const disable = useMemo(
    () => (komitmens.commit === "" ? true : false),
    [komitmens.commit]
  );

  const handleEditorReady = useCallback((editor) => {
    console.log("Editor is ready to use!", editor);
  }, []);

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
          setIsEdit(false);
        }}
        icon={<PlusCircleFilled />}
      >
        Tambahkan Komitmen
      </Button>
      <div className="gx-card gx-p-3">
        {listKomitmen.length === 0 ? (
          <>Tidak ada Komitmen</>
        ) : (
          <div>
            <Timeline mode="left">
              {listKomitmen.map((e, i) => {
                return (
                  <div key={e?._id}>
                    <Timeline.Item
                      color={
                        e.user.jabatan?.split(" ")?.shift() === "Direktur"
                          ? "green"
                          : "blue"
                      }
                    >
                      <Card
                        size="small"
                        title={
                          <span>
                            <UserOutlined
                              className={
                                e.user.jabatan?.split(" ")?.shift() ===
                                "Direktur"
                                  ? "gx-text-success"
                                  : e.user.jabatan?.split(" ")?.shift() ===
                                    "Komisaris"
                                  ? "gx-text-danger"
                                  : "gx-text-primary"
                              }
                            />{" "}
                            {e.user.name} - {e.user.jabatan} -{" "}
                            {moment(moment(e.tglLaporan, "YYYY-MM-DD")).format(
                              "DD-MMM-YYYY"
                            )}
                          </span>
                        }
                        extra={getExtra(e)}
                      >
                        {e.commit && <p>Komitmen : {e.commit}</p>}
                        <div
                          dangerouslySetInnerHTML={{ __html: e.description }}
                        />
                        <br />
                        {/* ;<p>{e.description}</p> */}
                        {isMobile && (
                          <b>
                            {moment(moment(e.tglLaporan, "YYYY-MM-DD")).format(
                              "DD-MMM-YYYY"
                            )}
                          </b>
                        )}
                      </Card>
                    </Timeline.Item>
                  </div>
                );
              })}
            </Timeline>
          </div>
        )}
      </div>

      <Modal
        open={visible}
        onCancel={handleCloseModal}
        title={`Komitmen Pimpinan Cabang ${getUser().kantorCabang}`}
        footer={null}
        width={1000}
      >
        <Spin spinning={loading} size="large">
          {!isEdit && (
            <>
              <Button
                className="btn btn-block gx-mt-2"
                type="primary"
                icon={<PlusCircleFilled />}
                onClick={() => appendField()}
              >
                Komitmen Baru
              </Button>
            </>
          )}

          {komitmens.map((value, i) => {
            return (
              <div key={i}>
                <Card>
                  {!isEdit && (
                    <div className="gx-text-right">
                      <DeleteOutlined
                        className="gx-text-danger"
                        onClick={() => deleteKomitmen(i)}
                      />
                    </div>
                  )}

                  <p>Kategori Komitmen (Wajib diisi)</p>
                  <Select
                    showSearch
                    value={value.commit}
                    className="gx-mb-2"
                    style={{ width: "100%" }}
                    onChange={(value) => handleChange(value, i, "commit")}
                  >
                    {commit.map((f, r) => {
                      return (
                        <Select.Option value={f} key={r}>
                          {f}
                        </Select.Option>
                      );
                    })}
                  </Select>
                  <p>Keterangan Tambahan (Wajib diisi)</p>

                  <CKEditor
                    editor={ClassicEditor}
                    data={value.description}
                    onReady={handleEditorReady}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      handleChange(data, i, "description");
                    }}
                    id={`editor${i}`}
                  />

                  <p>Tanggal. Laporan</p>
                  <Input value={colRealisasi} disabled={true} />
                </Card>
              </div>
            );
          })}

          <hr />
          <Row>
            <Col span={12}>
              <Button
                className="gx-btn-block"
                type="danger"
                ghost
                onClick={() => setVisible(false)}
              >
                Tutup
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="gx-btn-block"
                type="primary"
                disabled={disable}
                onClick={() => handleSave()}
              >
                Simpan
              </Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </div>
  );
}
