import { Button, Input, Select, Form, Row, Col } from 'antd';
import { paramKantorCabang, paramUserLevel } from 'dummy/parameter';
import React from 'react'

const FormItem = Form.Item
const label = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};


export default function UserDetail({ data }) {
    console.log("data", data)
    const [form] = Form.useForm();
    const onFinish = (value) => {
        console.log(value)
    }
    return (
        <div>
            <Form
                {...label}
                name="control-hooks"
                form={form}
                onFinish={onFinish}
                fields={[
                    {
                        name: ["name"],
                        value: data.name
                    },
                    {
                        name: ["username"],
                        value: data.username
                    },
                    {
                        name: ["code"],
                        value: data.code
                    },
                    {
                        name: ["nik"],
                        value: data.nik
                    },
                    {
                        name: ["level"],
                        value: data.level
                    },
                    {
                        name: ["kantorCabang"],
                        value: data.kantorCabang
                    }
                ]}>
                <Row gutter={[8, 8]}>
                    <Col lg={12} md={12} sm={24} xs={24}>
                    <FormItem name="username" rules={[{ required: true }]} label="Username" >
                    <Input />
                    </FormItem>
                    </Col>
                    <Col lg={12} md={12} sm={24} xs={24}>
                    <FormItem name="code" rules={[{ required: true }]} label="Kode" >
                    <Input />
                    </FormItem>
                    </Col>
                </Row>
                <FormItem name="name" rules={[{ required: true }]} label="Nama Lengkap" >
                    <Input />
                </FormItem>
                <FormItem name="nik" rules={[{ required: true }]} label="NIK" >
                    <Input />
                </FormItem>

                <FormItem name="level" rules={[{ required: true }]} label="Level User" >
                    <Select showSearch>
                        {paramUserLevel.map((e, i) => {
                            return (
                                <Select.Option value={e.name} key={i} >{e.name}</Select.Option>
                            )
                        })}
                    </Select>
                </FormItem>
                <FormItem name="kantorCabang" rules={[{ required: true }]} label="Kantor User" >
                    <Select showSearch>
                        {paramKantorCabang.map((e, i) => {
                            return (
                                <Select.Option value={e.NamaCabang} key={i} >{e.NamaCabang}</Select.Option>
                            )
                        })}
                    </Select>
                </FormItem>
                <div className="gx-text-right">
                    <Button htmlType="submit" type="primary">Simpan</Button>
                </div>
            </Form>
        </div>
    )
}
