import { Modal, Select, Skeleton, Table, Input, Divider, Button, Spin, Space } from 'antd'
import { GET_PARAM_KOMPONEN, UPDATE_PARAM_FINANCE } from 'API/Params'
import React, { useState, useEffect } from 'react'
import { notif } from 'util/notification'

export default function ListData({ data,  loading, refreshData , handleTableChange, tableParams}) {
    const [visible, setVisible] = useState(false)
    const [datas, setDatas] = useState([])
    const [component, setComponent] = useState([])
    const [load, setLoading] = useState(false)
    const [open, setOpen] = useState('')
    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        try {
            const { data } = await GET_PARAM_KOMPONEN("?page=1&limit=1000")
            setComponent(data.docs)
        } catch (e) { }
    }
    const handleKomponen = (data) => {
        if (data.code.length > 0) {
            return data.code.map(e => <span className="gx-text-warning gx-mr-1" style={{ cursor: "pointer" }} onClick={() => { { setVisible(true) }; setDatas(data) }}>{e.name}</span>)
        } else {
            return <span className="gx-text-primary" onClick={() => { setVisible(true); setDatas(data) }} style={{ cursor: "pointer" }}>Pilih Komponen</span>
        }
    }
    const col = [
        { dataIndex: "nosbb", title: "No. SBB", key: "_id"},
        { dataIndex: "kodeloc", title: "Kode Loc" },
        { dataIndex: "nmsbb", title: "Keterangan" },
        { title: "Komponen", render: (record) => handleKomponen(record) },
    ]
    const handleCode = (value) => {
        // console.log("val", value)
        const data = { ...datas }
        data.code_ = [...value]
        setDatas(data)
        // setOpen(false)
    }

    const handleSaveComponent = async () => {
        setLoading(true)
        try {
            const { data } = await UPDATE_PARAM_FINANCE(datas.id, { code: datas.code_ })
            // console.log(data)
            if (data.status) {
                setVisible(false)
                notif("success", "Sukses!", "Data Berhasil Disimpan!")
                refreshData()
            } else {
                notif("error", "Error!", data.message)
            }

        } catch (e) {
            notif("error", "Error!", "Data Gagal Disimpan")
        } finally { setLoading(false) }
    }
    const handleCancel = () => {
        setVisible(false)
    }
    return (
        <div>
            <Skeleton loading={loading} animated={true} rows={10}>
                <Table dataSource={data} columns={col} rowKey="_id" pagination={tableParams.pagination} 
                scroll={{y : 700}} size="small" onChange={handleTableChange}/>
            </Skeleton>
            <Modal  open={visible} onCancel={() => setVisible(false)} title="Tambah Komponen" footer={null}>
                <Spin spinning={load}>
                    <Input value={`${datas.nosbb}  - ${datas?.kodeloc} - ${datas?.nmsbb}`} />
                    <Select mode="multiple" className="gx-mt-2" style={{ width: '100%' }} onChange={(value) => handleCode(value)} value={datas.code_} showSearch
                     filterOption={(inputValue, option) =>
                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                      }
                      open={open}
                      onSelect={() => setOpen(false)}
                      onFocus={() => setOpen(true)}
                      onBlur={() => setOpen(false)}
                      onSearch={() => setOpen(true)}
                    >
                        {component.map((e, i) => {
                            return <Select.Option value={e.id} key={i}>{e.name} - {e.code}</Select.Option>
                        })}
                    </Select>
                    <Divider className="gx-mt-2 gx-mb-1" />
                    <div className="gx-text-right">
                        <Space>
                        <Button type="danger" ghost onClick={() => handleCancel()}>Tutup</Button>
                        <Button type="primary" onClick={() => handleSaveComponent()}>Simpan</Button>
                        </Space>
                    </div>
                </Spin>
            </Modal>
        </div>
    )
}
