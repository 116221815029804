import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Table, Image } from 'antd'
import React, { useState } from 'react'

export default function ListUsers({ data, setVisible, setDetail, setTitleModal, searchValue }) {
    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState(false)
    const [name, setName] = useState("")
    const editData = (data) => {
        setVisible(true)
        setDetail(data)
        setTitleModal("Edit Data User")
    }

    const confirm = (data) => {
        Modal.confirm({
            title: 'Konfirmasi',
            icon: <ExclamationCircleOutlined />,
            content: `Apakah anda yakin untuk menghapus ${data.name} ? Data akan terhapus secara permanen.`,
            okText: 'Hapus',
            cancelText: 'Tutup',
        });
    };


    const handleActiion = (record) => {
        return <Space><EditOutlined className="gx-text-primary" style={{ cursor: 'pointer' }} onClick={() => editData(record)} disabled={true} /> <DeleteOutlined className="gx-text-danger" onClick={() => confirm(record)} /></Space>
    }
    const columns = [
        {
            title: "Username",
            dataIndex: "username",
            filteredValue: [searchValue],
            onFilter: (value, record) => {
                return (
                    String(record.username)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.name)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.level)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.kantorCabang)
                        .toLowerCase()
                        .includes(value.toLowerCase())
                )
            }
        },
        {
            title: "Kode",
            dataIndex: "code",
            filteredValue: [searchValue],

        },
        {
            title: "Nama Lengkap",
            dataIndex: "name",

        },
        {
            title: "NIK",
            dataIndex: "nik"
        },
        {
            title: "Level",
            dataIndex: "level",

        },
        {
            title: "Kantor",
            dataIndex: "kantorCabang",

        },

        {
            title: "Signature",
            render: (record) => record.ttd ? <Button onClick={() => { setOpen(true); setUrl(record.ttd.uri); setName(record.name) }} size="small" type="primary" ghost>LIHAT TTD</Button> : "",
            // align: "right"
        },
        {
            title: "Aksi",
            width: 100,
            render: (record) => handleActiion(record),
        },

    ]
    return (
        <div>
            <Table dataSource={data} columns={columns} scroll={{ x: 900 }} rowKey="id" size='small' />
            <Modal visible={open} onCancel={() => setOpen(false)} title={`Tanda Tangan ${name}`} footer={null}>
                <Image src={url} />
            </Modal>
        </div>
    )
}
