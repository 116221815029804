import { Skeleton, Table } from 'antd'
import React, { } from 'react'
import { currFormat } from 'util/functions'
export default function ListData({ data, loading, searchValue }) {
    const col = [
        {
            title: "No. SBB", key: "_id",
            dataIndex: "nosbb",
            filteredValue: [searchValue],
            onFilter: (value, record) => {
                return (
                    String(record.nosbb)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.kodeloc)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.value)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                    String(record.kantor)
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                        String(record.nmsbb)
                        .toLowerCase()
                        .includes(value.toLowerCase())

                )
            },
            sorter: (a, b) => a.nosbb - b.nosbb
        },
        { title: "Keterangan", dataIndex: "nmsbb" },

        { dataIndex: "kodeloc", title: "Kode Loc", sorter: (a, b) => a.kodeloc - b.kodeloc },
        { title: "Kantor", dataIndex: "kantor" },
        { title: "Value", render: (record) => `${currFormat(record.value)}`, align: "right", sorter: (a, b) => a.value - b.value  } ,
        // { title: "Komponen", render: (record) => handleKomponen(record) },
    ]
    return (
        <div>
            <Skeleton loading={loading} animated={true} rows={10}>
                <Table dataSource={data} columns={col} rowKey="_id" pagination={true} size="middle"
                    scroll={{ y: 700 }} showTotal />
            </Skeleton>
            <div className="gx-text-right gx-mt-2 gx-mb-3">
            </div>
        </div>
    )
}
