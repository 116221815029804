import { CheckCircleFilled, CloseCircleFilled, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Button, Modal, Space, Table, Form, Input, Divider, Row, Col, Select, Spin } from 'antd'
import { DELETE_PARAM_GROUP_KOMPONEN, GET_PARAM_GROUP_KOMPONEN, POST_PARAM_GROUP_KOMPONEN, UPDATE_PARAM_GROUP_KOMPONEN } from 'API/Params'
import React, { useState, useEffect } from 'react'
import { notif } from 'util/notification'
const FormItem = Form.Item
const label = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};
export default function GroupLaporan() {
    const [visible, setVisible] = useState(false)
    const [datas, setDatas] = useState({})
    const [editable, setEditable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [groupData, setGroupData] = useState([])
    const [titleModal, setTitleModal] = useState("Group Komponen Baru")
    const [form] = Form.useForm();
    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        try {
            const { data } = await GET_PARAM_GROUP_KOMPONEN()
            setGroupData(data.docs)
        } catch (e) {

        }
    }
    const newCabang = () => {
        form.resetFields()
        setVisible(true)
        setTitleModal("Group Komponen Baru")
        setDatas({})
        setEditable(false)
    }
    const confirm = (data) => {
        console.log("data", data)
        Modal.confirm({
            title: 'Konfirmasi',
            icon: <ExclamationCircleOutlined />,
            content: `Apakah anda yakin untuk menghapus ${data.name} ? Data akan terhapus secara permanen.`,
            okText: 'Hapus',
            cancelText: 'Tutup',
            onOk: () => deleteItem(data)
        });
    };

    const deleteItem = async (data) => {
        console.log("sampe",data);
        try {
            await DELETE_PARAM_GROUP_KOMPONEN(data._id)
            notif("success", "Sukses!", "Berhasil Menghapus Data!")
            getData()
        } catch (e) { }
    }

    const handleActiion = (record) => {
        return <Space><EditOutlined className="gx-text-primary" style={{ cursor: 'pointer' }} onClick={() => { setDatas(record); setVisible(true); setTitleModal("Edit Komponen"); setEditable(true) }} /> <DeleteOutlined className="gx-text-danger" onClick={() => confirm(record)} /></Space>
    }
    const onFinish = async (value) => {
        setLoading(true)
        try {
            editable ? await UPDATE_PARAM_GROUP_KOMPONEN(datas._id, value) : await POST_PARAM_GROUP_KOMPONEN(value)
            notif("success", "Success!", "Data Berhasil Disimpan!")
            setVisible(false)
            getData()
        } catch (e) {

        } finally {
            setLoading(false)
        }

    }
    const columns = [
        {
            title: "Nama Group Komponen",
            dataIndex: "name",
            width: 200,
            key: "id"
        },
        {
            title: "Perhitungan",
            render: (record) => record.calculated ? <CheckCircleFilled style={{ color: "green" }} /> : <CloseCircleFilled style={{ color: "red" }} />,
            width: 200
        },
        {
            title: "Aksi",
            width: 100,
            render: (record) => record.name === "Main" ? "" : handleActiion(record),
            align: "right"
        }
    ]
   
    return (
        <>
            <Row gutter={[8, 8]}>
                <Col lg={18} md={18} sm={12} xs={12}>
                    <Input.Search placeholder="Cari Nama Group.." />
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Button className="gx-btn-block" type="primary" icon={<PlusCircleFilled />} onClick={() => newCabang()}>Grup Baru</Button>
                </Col>
            </Row>

            <div className="gx-module-box-column">
            {/* result.sort((a, b) => a.noUrut - b.noUrut); */}
                <Table dataSource={groupData} columns={columns} rowKey="id" rowClassName={(_record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'} />
                <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
                    <h3>{titleModal}</h3>
                    <Divider />
                    <Spin spinning={loading}>
                        <Form
                            {...label}
                            name="control-hooks"
                            form={form}
                            onFinish={onFinish}
                            fields={[
                                {
                                    name: ["name"],
                                    value: datas.name
                                },
                                {
                                    name: ["calculated"],
                                    value: datas.calculated
                                }
                            ]}>
                            <FormItem name="name" rules={[{ required: true }]} label="Nama Group" >
                                <Input />
                            </FormItem>
                            <FormItem name="calculated" rules={[{ required: true }]} label="Perhitungan" >
                                <Select>
                                    <Select.Option value={true}>Iya</Select.Option>
                                    <Select.Option value={false}>Tidak</Select.Option>
                                </Select>
                            </FormItem>

                            <div className="gx-text-right">
                                <Button htmlType="submit" type="primary">Simpan</Button>
                            </div>
                        </Form>
                    </Spin>
                </Modal>
            </div>
        </>
    )
}
