import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Button, Modal, Space, Table,AutoComplete, Form, Input, Divider, Row, Col, Select, Spin, Skeleton, Pagination } from 'antd'
import { GET_PARAM_GROUP_KOMPONEN, GET_PARAM_KOMPONEN, POST_PARAM_KOMPONEN, UPDATE_PARAM_KOMPONEN, DELETE_PARAM_KOMPONEN } from 'API/Params'
import React, { useState , useEffect} from 'react'
import { notif } from 'util/notification'
// import GroupLaporan from './GroupLaporan'

const FormItem = Form.Item
const label = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};


export default function LaporanKinerja() {
    const [visible, setVisible] = useState(false)
    const [loading, setLoading] = useState(false)
    const [datas, setDatas] = useState({})
    const [titleModal, setTitleModal] = useState("Komponen Baru")
    const [groupLaporan, setGroupLaporan] = useState([])
    const [laporan, setLaporan] = useState()
    const [editable, setEditable] = useState(false)
    const [form] = Form.useForm();
    const [query, setQuery] = useState("")
    // let titleModal = "Cabang Baru"
    const [totalPage, setTotalPage] = useState("")
    const [page, setPage] = useState("")
    const [limit, setLimit] = useState("")
    const [totalRows, setTotalRows] = useState("")

    useEffect(() => {
        getData()
    },[])
    const getData = async (page, size, query) => {
        const param = typeof page !== "undefined" ? `?page=${page}&limit=${size}&query=${query}` : ""
        setLoading(true)
        try{
            const {data : x} = await GET_PARAM_GROUP_KOMPONEN()
            const {data : y} = await GET_PARAM_KOMPONEN(param)
            // console.log("data",y)
            setGroupLaporan(x.docs)
            const sortData = y.docs.sort((a, b) => a.nourut - b.nourut)
            setTotalPage(y.totalPages)
            setPage(y.page)
            setLimit(y.limit)
            setTotalRows(y.totalDocs)
            setLaporan(sortData)
        }catch(e){
            notif("error", "Gagal", "Gagal Saat Mengambil Data")

        }finally{setLoading(false)}
    }
    const newCabang = () => {
        form.resetFields()
        setVisible(true)
        setTitleModal("Komponen Baru")
        setDatas({})
        setEditable(false)
    }
    const confirm = (data) => {
        Modal.confirm({
            title: 'Konfirmasi',
            icon: <ExclamationCircleOutlined />,
            content: `Apakah anda yakin untuk menghapus ${data.name} ? Data akan terhapus secara permanen.`,
            okText: 'Hapus',
            cancelText: 'Tutup',
            onOk : () => deleteItem(data.id)
        });
    };
    const deleteItem = async (data) => {
        console.log("okee",data)
        try{
            const {data : item} = await DELETE_PARAM_KOMPONEN(data)
            console.log(item)
            if(item.deleted){
                notif("success", "Berhasil!"," Data Berhasil Dihapus!")
                getData(page, limit, "")
            }else{
                notif("error", "Gagal!", `${item.message}`)
            }
           
        }catch(e){
            notif("error", "Gagal!","Data Gagal Dihapus!")
        }
    }
    


    const handleActiion = (record) => {
        return <Space><EditOutlined className="gx-text-primary" style={{ cursor: 'pointer' }} onClick={() => { setDatas(record); setVisible(true); setTitleModal("Edit Komponen"); setEditable(true) }} /> <DeleteOutlined className="gx-text-danger" onClick={() => confirm(record)} /></Space>
    }
    const columns = [
        {
            title: "No. Urut",
            dataIndex: "nourut",
            width: 200
        },
        {
            title: "Kode Komponen",
            dataIndex: "code",
            width: 200
        },
        {
            title: "Nama Komponen",
            dataIndex: "name",
            width: 200
        },
        {
            title: "Group Komponen",
            // dataIndex: "group",
            render : (record) => record.group ? record.group.name: "",
            width: 200
        },
        {
            title: "Visibility",
            // dataIndex: "visiblity",
            render: (record) => record.visibility ? record.visibility.map(e => <Button type="primary" size="small" ghost>{e}</Button>) : "",
            width: 200,
        },
        {
            title: "Aksi",
            width: 100,
            render: (record) => handleActiion(record),
            align: "right"
        }
    ]
    const onFinish = async (value) => {
        // console.log("finish", value)
        setLoading(true)
        try{
            const {data : x} = editable ? await UPDATE_PARAM_KOMPONEN(datas._id, value) : await POST_PARAM_KOMPONEN(value)
            console.log(x)
            if(x.status){
                notif("success", "Sukses", "Data Berhasil Disimpan!")
                setVisible(false)
                getData(page, limit, "")
            }else{
                notif("error", "Gagal!", "Kode sudah ada dalam sistem")
            }
        }catch(e){
            notif("error", "Gagal", "Data Gagal Disimpan")
        }finally{
            setLoading(false)
        }
    }
    const onPaginationChange = (page, size) => {
        getData(page, size, query === "" ? "" : query)
        setPage(page)
        // console.log("size", page, size)
        setLimit(size)
    }
    const handleSearch = (value) => {
        const param = `${value}`
        setQuery(param)
        value !== "" ? getData(1, limit, param) : getData(1, limit, "")
    }

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col lg={18} md={18} sm={12} xs={12}>
                <AutoComplete
                style={{
                    width: "100%",
                }}
                onSearch={handleSearch}
            >
                <Input.Search  placeholder="Cari Komponen.." enterButton />
            </AutoComplete>
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Button className="gx-btn-block" type="primary" icon={<PlusCircleFilled />} onClick={() => newCabang()}>Komponen Baru</Button>
                </Col>
            </Row>

            <div className="gx-module-box-column">
                <Skeleton loading={loading} animated={true}>
                <Table dataSource={laporan} columns={columns} rowKey="id" rowClassName={(_record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'} scroll={{ x: 500 }} pagination={false} size="small"/>
                <div className="gx-text-right gx-mt-2">
                <Pagination
                 pageSize={limit}
                 current={page}
                 total={totalRows}
                 totalRows={totalPage}
                 onChange={onPaginationChange}
                 showSizeChanger={true}
                //  showTotal={totalRows}
                />
                </div>
                </Skeleton>
                <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
                    <h3>{titleModal}</h3>
                    <Divider />
                    <Spin spinning={loading}>
                    <Form
                        {...label}
                        name="control-hooks"
                        form={form}
                        onFinish={onFinish}
                        fields={[
                            {
                                name: ["nourut"],
                                value: datas.nourut
                            },
                            {
                                name: ["code"],
                                value: datas.code
                            },
                            {
                                name: ["name"],
                                value: datas.name
                            },
                            {
                                name: ["group"],
                                value: datas.group?._id
                            },
                            {
                                name: ["visibility"],
                                value: datas.visibility
                            },
                        ]}>
                        <FormItem name="nourut" rules={[{ required: true }]} label="No. Urut" >
                            <Input />
                        </FormItem>
                        <FormItem name="code" rules={[{ required: true }]} label="Kode" >
                            <Input />
                        </FormItem>
                        <FormItem name="name" rules={[{ required: true }]} label="Nama Komponen" >
                            <Input />
                        </FormItem>
                        <FormItem name="group" rules={[{ required: true }]} label="Group" >
                            <Select>
                                {groupLaporan.map((e, i) => {
                                    return <Select.Option value={e.id} key={i}>{e.name}</Select.Option>
                                })}
                            </Select>
                        </FormItem>
                        <FormItem name="visibility" rules={[{ required: true }]} label="Visibility (Berdasarakn Tampilan User)" >
                            <Select mode={"tags"}>
                              <Select.Option value="PINCA">PINCA</Select.Option>
                              <Select.Option value="DIREKSI">DIREKSI</Select.Option>
                              <Select.Option value="KOMISARIS">KOMISARIS</Select.Option>
                            </Select>
                        </FormItem>
                        <div className="gx-text-right">
                            <Button htmlType="submit" type="primary">Simpan</Button>
                        </div>
                    </Form>       
                    </Spin>
                </Modal>
            </div>
        </>

    )
}
