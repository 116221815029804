import React from "react";
import {useDispatch} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { getUser } from "util/DataUser";

const UserProfile = () => {
  console.log("getUser", getUser())
  const dispatch = useDispatch();
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>My Account</li>
      <li>Connections</li>
      <li onClick={() => dispatch(userSignOut())}>Logout
      </li>
    </ul>
  );
  var name = getUser().name ? getUser().name : "No User"
  var length = 13;
  var user_name = name.substring(0, length);
  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-2 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={"https://via.placeholder.com/150"}
                className="gx-size-30 gx-pointer gx-mr-2" alt=""/>
        <span className="gx-avatar-name">{name.length > 14 ? user_name + ".." : user_name }<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
          <br/>
          {/* <span>{getUser().level}</span> */}
      </Popover>
    </div>

  )
};

export default UserProfile;
