export const paramPerbaikanKerja = [
  {
    id: 0,
    name: "Meningkatkan DPK, Pembiayaan & Perbaikan NPF"
  },
  {
    id: 1,
    name: "Meningkatkan Pembiayaan"
  },
  {
    id: 2,
    name: "Meningkatkan DPK"
  },
  {
    id: 3,
    name: "Perbaikan NPF"
  },
]
export const groupLaporanDewan = [
  {
    "group": "Main",
    "id_group": "01",
    "calculated": false,
  },
  {
    "group": "Saldo Pos Utama",
    "id_group": "02",
    "calculated": "TRUE"
  },
  {
    "group": "Rincian Kas dan Bank",
    "id_group": "03",
    "calculated": "FALSE"
  }
]
export const paramUserLevel = [
  {
    "id": 1,
    "name": "Account Officer"
  },
  {
    "id": 2,
    "name": "Pimpinan Cabang"
  },
  {
    "id": 3,
    "name": "Direktur Utama"
  },
  {
    "id": 4,
    "name": "Direktur Kepatuhan"
  },
  {
    "id": 5,
    "name": "Direktur Bisnis"
  },
  {
    "id": 6,
    "name": "Komisaris Utama"
  },
  {
    "id": 7,
    "name": "Komisaris"
  },
  {
    "id": 8,
    "name": "Admin"
  },
  {
    "id": 9,
    "name": "Legal"
  },
  {
    "id": 10,
    "name": "CS"
  },
  {
    "id": 11,
    "name": "BI Checking"
  },
  {
    "id": 12,
    "name": "Remedial"
  }
]
export const paramLaporanDewan = [
  {
    "noUrut": 1,
    "kode": 401,
    "komponen": "Pendapatan Margin dan Bahas",
    "group": "Main",
    "id_group": "01"
  },
  {
    "noUrut": 2,
    "kode": 409,
    "komponen": "Pendapatan Ops Lain",
    "group": "Main",
    "id_group": "01"
  },
  {
    "noUrut": 3,
    "kode": 501.502,
    "komponen": "Biaya Bahas",
    "group": "Main",
    "id_group": "01"
  },
  {
    "noUrut": 4,
    "kode": 504,
    "komponen": "Biaya Tenaga Kerja (Termasuk Bonus)",
    "group": "Main",
    "id_group": "01"
  },
  {
    "noUrut": 5,
    "kode": 509,
    "komponen": "Biaya Ops lain",
    "group": "Main",
    "id_group": "01"
  },
  {
    "noUrut": 6,
    "kode": 601,
    "komponen": "Pajak",
    "group": "Main",
    "id_group": "01"
  },
  {
    "noUrut": 7,
    "kode": 602,
    "komponen": "Zakat",
    "group": "Main",
    "id_group": "01"
  },
  {
    "noUrut": 8,
    "kode": 700,
    "komponen": "Laba Rugi                                                   (sesudah Pajak PPh 25 bulanan dan zakat, bonus )",
    "group": "Main",
    "id_group": "01"
  },
  {
    "noUrut": 9,
    "kode": 130,
    "komponen": "PEMBIAYAAN",
    "group": "Saldo Pos Utama",
    "id_group": "02"
  },
  {
    "noUrut": 10,
    "kode": 331,
    "komponen": "TABUNGAN",
    "group": "Saldo Pos Utama",
    "id_group": "02"
  },
  {
    "noUrut": 11,
    "kode": 332,
    "komponen": "DEPOSITO",
    "group": "Saldo Pos Utama",
    "id_group": "02"
  },
  {
    "noUrut": 12,
    "kode": "total npf/130",
    "komponen": "NPF",
    "group": "Saldo Pos Utama",
    "id_group": "02"
  },
  {
    "noUrut": 13,
    "kode": "(501,502)/(331,332)",
    "komponen": "Biaya Bahas DPK",
    "group": "Saldo Pos Utama",
    "id_group": "02"
  },
  {
    "noUrut": 14,
    "kode": "(401n) - (401n-1)",
    "komponen": "Produktifitas Pembiayaan",
    "group": "Saldo Pos Utama",
    "id_group": "02"
  },
  {
    "noUrut": 15,
    "kode": "",
    "komponen": "CASH DAN BANK",
    "group": "Saldo Pos Utama",
    "id_group": "02"
  },
  {
    "noUrut": 16,
    "kode": 100,
    "komponen": "Rincian Kas dan Bank",
    "group": "Rincian Kas dan Bank",
    "id_group": "03"
  },
  {
    "noUrut": 17,
    "kode": 101,
    "komponen": "- Kas, Giro, Tabungan",
    "group": "Rincian Kas dan Bank",
    "id_group": "03"
  },
  {
    "noUrut": 18,
    "kode": 102,
    "komponen": "- Deposito",
    "group": "Rincian Kas dan Bank",
    "id_group": "03"
  },
  {
    "noUrut": 19,
    "kode": 402,
    "komponen": "Produktifitas Kas dan Bank",
    "group": "Rincian Kas dan Bank",
    "id_group": "03"
  }
]
export const paramLaporanKerja = [
  {
    "noUrut": 1,
    "Kode": 290,
    "Komponen": "Aset",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 2,
    "Kode": 130,
    "Komponen": "Pembiayaan",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 3,
    "Kode": 260,
    "Komponen": "Aset Antar Kantor (RAK Aktiva)",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 4,
    "Kode": 331,
    "Komponen": "DPK-Tabungan",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 5,
    "Kode": 332,
    "Komponen": "DPK-Deposito",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 6,
    "Kode": 350,
    "Komponen": "ABP - Tabungan & Deposito",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 7,
    "Kode": 360,
    "Komponen": "Pembiayaan dari Bank lain",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 8,
    "Kode": 370,
    "Komponen": "Liabilitas Antar Kantor (RAK Pasiva)",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 9,
    "Kode": 700,
    "Komponen": "Laba Th.Berjalan",
    "group": "Main",
    "id_group": "01",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 10,
    "Kode": 401,
    "Komponen": "- Pendapatan dari Pembiayaan",
    "group": "Pendapatan",
    "id_group": "02",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 11,
    "Kode": 402,
    "Komponen": "- Pendapatan dari Bank Lain (PBL)",
    "group": "Pendapatan",
    "id_group": "02",
    "visibility" : ["PINCA"]
  },
  {
    "noUrut": 12,
    "Kode": 403,
    "Komponen": "- Pendapatan RAK",
    "group": "Pendapatan",
    "id_group": "02",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 13,
    "Kode": 409,
    "Komponen": "- Pendapatan lainnya",
    "group": "Pendapatan",
    "id_group": "02",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 14,
    "Kode": 501,
    "Komponen": "- Bagihasil DPK",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 15,
    "Kode": 502,
    "Komponen": "- Bagihasil Pinjaman Bank lain",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 16,
    "Kode": 503,
    "Komponen": "- Bagihasil Pinjaman RAK",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 17,
    "Kode": 504,
    "Komponen": "- Biaya Tenaga Kerja",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 18,
    "Kode": 509,
    "Komponen": "- Biaya Operasional lain",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 19,
    "Kode": 599,
    "Komponen": "- Biaya Non Operasional",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 20,
    "Kode": 601,
    "Komponen": "Zakat",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 21,
    "Kode": 602,
    "Komponen": "Pajak Penghasilan",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 22,
    "Kode": 701,
    "Komponen": "Laba/rugi",
    "group": "Biaya",
    "id_group": "03",
    "visibility" : []
  },
  {
    "noUrut": 23,
    "Kode": "R001",
    "Komponen": "- FDR-1 (Pemby/DPK)",
    "group": "Rasio",
    "id_group": "04",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 24,
    "Kode": "R002",
    "Komponen": "- FDR-2 (Pemby/(DPK+ABP+RAK)",
    "group": "Rasio",
    "id_group": "04",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 25,
    "Kode": "R003",
    "Komponen": "- Non Performing Financing (NPF)",
    "group": "Rasio",
    "id_group": "04",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 26,
    "Kode": "R004",
    "Komponen": "- Produktifitas Pembiayaan",
    "group": "Rasio",
    "id_group": "04",
    "visibility" : ["PINCA", "DIREKSI"]
  },
  {
    "noUrut": 27,
    "Kode": "R005",
    "Komponen": "- Biaya Dana/Total Dana",
    "group": "Rasio",
    "id_group": "04",
    "visibility" : ["PINCA", "DIREKSI"]
  }
]
export const groupLaporan = [
  {
    name: "Main",
    id: "01",
    calculated : false,
  },
  {
    name: "Pendapatan",
    id: "02",
    calculated : true,
  },
  {
    name: "Biaya",
    id: "03",
    calculated : true,
  },
  {
    name: "Rasio",
    id: "04",
    calculated : false,
  },
]
export const paramKantorCabang = [
  {
    "KodeKantor": 0,
    "NamaCabang": "Gabungan",
    "KodeCabang": 0
  },
  {
    "KodeKantor": 1,
    "NamaCabang": "KPO Jombang",
    "KodeCabang": 1
  },
  {
    "KodeKantor": 2,
    "NamaCabang": "Kas Cukir",
    "KodeCabang": 1
  },
  {
    "KodeKantor": 3,
    "NamaCabang": "Kas Mojoagung",
    "KodeCabang": 1
  },
  {
    "KodeKantor": 4,
    "NamaCabang": "KC Mojokerto",
    "KodeCabang": 4
  },
  {
    "KodeKantor": 5,
    "NamaCabang": "Kas Mojosari",
    "KodeCabang": 4
  },
  {
    "KodeKantor": 6,
    "NamaCabang": "KC Gresik",
    "KodeCabang": 6
  },
  {
    "KodeKantor": 7,
    "NamaCabang": "KC Surabaya",
    "KodeCabang": 7
  },
  {
    "KodeKantor": 8,
    "NamaCabang": "Kas Bungurasih",
    "KodeCabang": 7
  },
  {
    "KodeKantor": 9,
    "NamaCabang": "KC Sidoarjo",
    "KodeCabang": 9
  },
  {
    "KodeKantor": 10,
    "NamaCabang": "Kas Krian",
    "KodeCabang": 9
  },
  {
    "KodeKantor": 11,
    "NamaCabang": "Kas Klampis",
    "KodeCabang": 7
  },
  {
    "KodeKantor": 12,
    "NamaCabang": "Kas Ronas",
    "KodeCabang": 4
  },
  {
    "KodeKantor": 13,
    "NamaCabang": "Kas Manyar",
    "KodeCabang": 6
  },
  {
    "KodeKantor": 14,
    "NamaCabang": "Kas Sidodadi",
    "KodeCabang": 7
  },
  {
    "KodeKantor": 15,
    "NamaCabang": "KC Lamongan",
    "KodeCabang": 15
  }
]
export const paramRbb = [
  {
    "No.": 1,
    "Uraian": "Aset",
    "Jan-22": "27.693.464.228",
    "Feb-22": "28.212.476.566",
    "Mar-22": "28.741.393.085",
    "Apr-22": "29.283.596.762",
    "Mei-22": "29.841.994.192",
    "Jun-22": "30.417.408.653",
    "Jul-22": "31.009.932.344",
    "Agu-22": "31.619.987.561",
    "Sep-22": "32.247.203.662",
    "Okt-22": "32.891.678.012",
    "Nov-22": "33.553.123.352",
    "Des-22": "34.234.722.714"
  },
  {
    "No.": 2,
    "Uraian": "Pembiayaan",
    "Jan-22": "16.829.278.833",
    "Feb-22": "17.453.278.356",
    "Mar-22": "18.083.277.902",
    "Apr-22": "18.713.277.471",
    "Mei-22": "19.325.277.062",
    "Jun-22": "19.919.276.673",
    "Jul-22": "20.507.276.303",
    "Agu-22": "21.077.275.953",
    "Sep-22": "21.677.275.620",
    "Okt-22": "22.277.275.303",
    "Nov-22": "22.877.275.001",
    "Des-22": "23.417.274.716"
  },
  {
    "No.": 3,
    "Uraian": "Aset Antar Kantor (RAK Aktiva)",
    "Jan-22": "9.065.573.300",
    "Feb-22": "8.987.573.300",
    "Mar-22": "8.582.573.300",
    "Apr-22": "8.491.573.300",
    "Mei-22": "8.434.573.300",
    "Jun-22": "8.409.573.300",
    "Jul-22": "8.409.573.300",
    "Agu-22": "8.444.573.300",
    "Sep-22": "8.468.573.300",
    "Okt-22": "8.508.573.300",
    "Nov-22": "8.563.573.300",
    "Des-22": "8.701.573.300"
  },
  {
    "No.": 4,
    "Uraian": "DPK-Tabungan",
    "Jan-22": "12.475.951.656",
    "Feb-22": "12.842.991.292",
    "Mar-22": "13.210.030.928",
    "Apr-22": "13.577.070.564",
    "Mei-22": "13.944.110.200",
    "Jun-22": "14.311.149.836",
    "Jul-22": "14.678.189.472",
    "Agu-22": "15.045.229.108",
    "Sep-22": "15.412.268.744",
    "Okt-22": "15.779.308.380",
    "Nov-22": "16.146.348.016",
    "Des-22": "16.513.387.652"
  },
  {
    "No.": 5,
    "Uraian": "DPK-Deposito",
    "Jan-22": "14.131.351.239",
    "Feb-22": "14.227.184.572",
    "Mar-22": "14.323.017.905",
    "Apr-22": "14.418.851.238",
    "Mei-22": "14.514.684.571",
    "Jun-22": "14.610.517.904",
    "Jul-22": "14.706.351.237",
    "Agu-22": "14.802.184.570",
    "Sep-22": "14.898.017.903",
    "Okt-22": "14.993.851.236",
    "Nov-22": "15.089.684.569",
    "Des-22": "15.185.517.902"
  },
  {
    "No.": 6,
    "Uraian": "ABP - Tabungan & Deposito",
    "Jan-22": "0",
    "Feb-22": "0",
    "Mar-22": "0",
    "Apr-22": "0",
    "Mei-22": "0",
    "Jun-22": "0",
    "Jul-22": "0",
    "Agu-22": "0",
    "Sep-22": "0",
    "Okt-22": "0",
    "Nov-22": "0",
    "Des-22": "0"
  },
  {
    "No.": 7,
    "Uraian": "Pembiayaan dari Bank lain",
    "Jan-22": "0",
    "Feb-22": "0",
    "Mar-22": "0",
    "Apr-22": "0",
    "Mei-22": "0",
    "Jun-22": "0",
    "Jul-22": "0",
    "Agu-22": "0",
    "Sep-22": "0",
    "Okt-22": "0",
    "Nov-22": "0",
    "Des-22": "0"
  },
  {
    "No.": 8,
    "Uraian": "Liabilitas Antar Kantor (RAK Pasiva)",
    "Jan-22": "0",
    "Feb-22": "0",
    "Mar-22": "0",
    "Apr-22": "0",
    "Mei-22": "0",
    "Jun-22": "0",
    "Jul-22": "0",
    "Agu-22": "0",
    "Sep-22": "0",
    "Okt-22": "0",
    "Nov-22": "0",
    "Des-22": "0"
  },
  {
    "No.": 9,
    "Uraian": "Laba Th.Berjalan",
    "Jan-22": "153.259.765",
    "Feb-22": "202.617.470",
    "Mar-22": "261.086.869",
    "Apr-22": "331.345.264",
    "Mei-22": "415.971.185",
    "Jun-22": "515.695.497",
    "Jul-22": "630.598.641",
    "Agu-22": "761.054.648",
    "Sep-22": "906.736.202",
    "Okt-22": "1.067.726.320",
    "Nov-22": "1.243.775.289",
    "Des-22": "1.437.704.785"
  },
  {
    "No.": null,
    "Uraian": "",
    "Jan-22": "",
    "Feb-22": "",
    "Mar-22": "",
    "Apr-22": "",
    "Mei-22": "",
    "Jun-22": "",
    "Jul-22": "",
    "Agu-22": "",
    "Sep-22": "",
    "Okt-22": "",
    "Nov-22": "",
    "Des-22": ""
  },
  {
    "No.": 10,
    "Uraian": "Pendapatan :",
    "Jan-22": "",
    "Feb-22": "",
    "Mar-22": "",
    "Apr-22": "",
    "Mei-22": "",
    "Jun-22": "",
    "Jul-22": "",
    "Agu-22": "",
    "Sep-22": "",
    "Okt-22": "",
    "Nov-22": "",
    "Des-22": ""
  },
  {
    "No.": null,
    "Uraian": "- Pendapatan dari Pembiayaan",
    "Jan-22": "321.051.591",
    "Feb-22": "548.011.475",
    "Mar-22": "791.352.305",
    "Apr-22": "1.051.696.700",
    "Mei-22": "1.329.600.039",
    "Jun-22": "1.625.711.360",
    "Jul-22": "1.940.212.138",
    "Agu-22": "2.273.108.355",
    "Sep-22": "2.624.870.450",
    "Okt-22": "2.994.996.093",
    "Nov-22": "3.384.627.717",
    "Des-22": "3.794.146.497"
  },
  {
    "No.": null,
    "Uraian": "- Pendapatan RAK",
    "Jan-22": "61.312.006",
    "Feb-22": "115.705.446",
    "Mar-22": "169.630.886",
    "Apr-22": "221.126.326",
    "Mei-22": "272.075.765",
    "Jun-22": "322.683.205",
    "Jul-22": "373.140.645",
    "Agu-22": "423.598.085",
    "Sep-22": "474.265.525",
    "Okt-22": "525.076.964",
    "Nov-22": "576.128.404",
    "Des-22": "627.509.844"
  },
  {
    "No.": null,
    "Uraian": "- Pendapatan lainnya",
    "Jan-22": "12.987.086",
    "Feb-22": "26.372.593",
    "Mar-22": "40.069.809",
    "Apr-22": "54.057.083",
    "Mei-22": "68.176.419",
    "Jun-22": "82.420.165",
    "Jul-22": "96.888.098",
    "Agu-22": "111.470.994",
    "Sep-22": "126.581.496",
    "Okt-22": "141.968.533",
    "Nov-22": "156.109.088",
    "Des-22": "169.969.679"
  },
  {
    "No.": null,
    "Uraian": "Jumlah Pendapatan",
    "Jan-22": "395.350.683",
    "Feb-22": "690.089.514",
    "Mar-22": "1.001.053.000",
    "Apr-22": "1.326.880.109",
    "Mei-22": "1.669.852.224",
    "Jun-22": "2.030.814.731",
    "Jul-22": "2.410.240.881",
    "Agu-22": "2.808.177.433",
    "Sep-22": "3.225.717.470",
    "Okt-22": "3.662.041.591",
    "Nov-22": "4.116.865.210",
    "Des-22": "4.591.626.019"
  },
  {
    "No.": 11,
    "Uraian": "Biaya :",
    "Jan-22": "",
    "Feb-22": "",
    "Mar-22": "",
    "Apr-22": "",
    "Mei-22": "",
    "Jun-22": "",
    "Jul-22": "",
    "Agu-22": "",
    "Sep-22": "",
    "Okt-22": "",
    "Nov-22": "",
    "Des-22": ""
  },
  {
    "No.": null,
    "Uraian": "- Bagihasil DPK",
    "Jan-22": "97.777.416",
    "Feb-22": "196.565.292",
    "Mar-22": "296.363.628",
    "Apr-22": "397.172.425",
    "Mei-22": "498.991.682",
    "Jun-22": "601.821.400",
    "Jul-22": "705.661.578",
    "Agu-22": "810.512.217",
    "Sep-22": "916.373.316",
    "Okt-22": "1.023.244.875",
    "Nov-22": "1.131.126.895",
    "Des-22": "1.240.019.375"
  },
  {
    "No.": null,
    "Uraian": "- Bagihasil Pinjaman Bank lain",
    "Jan-22": "0",
    "Feb-22": "0",
    "Mar-22": "0",
    "Apr-22": "0",
    "Mei-22": "0",
    "Jun-22": "0",
    "Jul-22": "0",
    "Agu-22": "0",
    "Sep-22": "0",
    "Okt-22": "0",
    "Nov-22": "0",
    "Des-22": "0"
  },
  {
    "No.": null,
    "Uraian": "- Bagihasil Pinjaman RAK",
    "Jan-22": "0",
    "Feb-22": "0",
    "Mar-22": "0",
    "Apr-22": "0",
    "Mei-22": "0",
    "Jun-22": "0",
    "Jul-22": "0",
    "Agu-22": "0",
    "Sep-22": "0",
    "Okt-22": "0",
    "Nov-22": "0",
    "Des-22": "0"
  },
  {
    "No.": null,
    "Uraian": "- Biaya Tenaga Kerja",
    "Jan-22": "80.942.083",
    "Feb-22": "165.884.167",
    "Mar-22": "250.826.250",
    "Apr-22": "335.768.334",
    "Mei-22": "420.710.417",
    "Jun-22": "505.652.500",
    "Jul-22": "590.594.584",
    "Agu-22": "675.536.667",
    "Sep-22": "760.478.750",
    "Okt-22": "845.420.834",
    "Nov-22": "930.362.917",
    "Des-22": "1.015.305.001"
  },
  {
    "No.": null,
    "Uraian": "- Biaya Operasional lain",
    "Jan-22": "63.272.419",
    "Feb-22": "124.824.585",
    "Mar-22": "192.479.253",
    "Apr-22": "262.198.086",
    "Mei-22": "333.683.940",
    "Jun-22": "407.051.333",
    "Jul-22": "482.693.078",
    "Agu-22": "560.281.901",
    "Sep-22": "641.238.202",
    "Okt-22": "724.659.562",
    "Nov-22": "810.511.108",
    "Des-22": "897.408.859"
  },
  {
    "No.": null,
    "Uraian": "- Biaya Non Operasional",
    "Jan-22": "99.000",
    "Feb-22": "198.000",
    "Mar-22": "297.000",
    "Apr-22": "396.000",
    "Mei-22": "495.000",
    "Jun-22": "594.000",
    "Jul-22": "693.000",
    "Agu-22": "792.000",
    "Sep-22": "891.000",
    "Okt-22": "990.000",
    "Nov-22": "1.089.000",
    "Des-22": "1.188.000"
  },
  {
    "No.": null,
    "Uraian": "Jumlah Biaya",
    "Jan-22": "242.090.918",
    "Feb-22": "487.472.044",
    "Mar-22": "739.966.131",
    "Apr-22": "995.534.845",
    "Mei-22": "1.253.881.039",
    "Jun-22": "1.515.119.233",
    "Jul-22": "1.779.642.239",
    "Agu-22": "2.047.122.785",
    "Sep-22": "2.318.981.268",
    "Okt-22": "2.594.315.271",
    "Nov-22": "2.873.089.920",
    "Des-22": "3.153.921.234"
  },
  {
    "No.": null,
    "Uraian": "Rasio - Rasio :",
    "Jan-22": "",
    "Feb-22": "",
    "Mar-22": "",
    "Apr-22": "",
    "Mei-22": "",
    "Jun-22": "",
    "Jul-22": "",
    "Agu-22": "",
    "Sep-22": "",
    "Okt-22": "",
    "Nov-22": "",
    "Des-22": ""
  },
  {
    "No.": null,
    "Uraian": "- FDR-1 (Pemby/DPK)",
    "Jan-22": "63,25%",
    "Feb-22": "64,47%",
    "Mar-22": "65,68%",
    "Apr-22": "66,84%",
    "Mei-22": "67,91%",
    "Jun-22": "68,87%",
    "Jul-22": "69,79%",
    "Agu-22": "70,62%",
    "Sep-22": "71,52%",
    "Okt-22": "72,39%",
    "Nov-22": "73,24%",
    "Des-22": "73,87%"
  },
  {
    "No.": null,
    "Uraian": "- FDR-2 (Pemby/(DPK+ABP+RAK)",
    "Jan-22": "63,25%",
    "Feb-22": "64,47%",
    "Mar-22": "65,68%",
    "Apr-22": "66,84%",
    "Mei-22": "67,91%",
    "Jun-22": "68,87%",
    "Jul-22": "69,79%",
    "Agu-22": "70,62%",
    "Sep-22": "71,52%",
    "Okt-22": "72,39%",
    "Nov-22": "73,24%",
    "Des-22": "73,87%"
  },
  {
    "No.": null,
    "Uraian": "- Non Performing Financing (NPF)",
    "Jan-22": "6,62%",
    "Feb-22": "6,44%",
    "Mar-22": "6,26%",
    "Apr-22": "6,08%",
    "Mei-22": "5,90%",
    "Jun-22": "5,73%",
    "Jul-22": "5,55%",
    "Agu-22": "5,37%",
    "Sep-22": "5,19%",
    "Okt-22": "5,01%",
    "Nov-22": "4,83%",
    "Des-22": "4,65%"
  },
  {
    "No.": null,
    "Uraian": "- Produktifitas Pembiayaan",
    "Jan-22": "1,27%",
    "Feb-22": "1,32%",
    "Mar-22": "1,34%",
    "Apr-22": "1,39%",
    "Mei-22": "1,44%",
    "Jun-22": "1,49%",
    "Jul-22": "1,53%",
    "Agu-22": "1,58%",
    "Sep-22": "1,62%",
    "Okt-22": "1,66%",
    "Nov-22": "1,70%",
    "Des-22": "1,75%"
  }
]

export const laporanDireksi = [
  {
    id : 0,
    code_component : 290, 
    rbbValue: "32247203662",
    realisasiValue: "27993932143",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "20904999900",
    lastMonthValue: "32147203650"
  },
  {
    id : 1,
    code_component : 130, 
    rbbValue: "21677275620",
    realisasiValue: "17440933103",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "20904999900",
    lastMonthValue: "21577275608"
  },
  {
    id : 2,
    code_component : 260, 
    rbbValue: "8468573300",
    realisasiValue: "54061903942",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "8368573288"
  },
  {
    id : 3,
    code_component : 331, 
    rbbValue: "15412268744",
    realisasiValue: "12212490021",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "12084989900",
    lastMonthValue: "15312268732"
  },
  {
    id : 4,
    code_component : 332, 
    rbbValue: "14798017891",
    realisasiValue: "14898017903",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "1400000000",
    lastMonthValue: "14798017891"
  },
  {
    id : 5,
    code_component : 350, 
    rbbValue: "0",
    realisasiValue: "0",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "50000000",
    lastMonthValue: "-100000012"
  },
  {
    id : 6,
    code_component : 360, 
    rbbValue: "0",
    realisasiValue: "0",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "0",
    lastMonthValue: "0"
  },
  {
    id : 7,
    code_component : 370, 
    rbbValue: "0",
    realisasiValue: "0",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "0",
    lastMonthValue: "0"
  },
  {
    id : 8,
    code_component : 700, 
    rbbValue: "906736202",
    realisasiValue: "1360048017",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "1000000000",
    lastMonthValue: "806736190"
  },
  {
    id : 9,
    code_component : 401, 
    rbbValue: "2624870450",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "8368573288"
  },
  {
    id : 10,
    code_component : 402, 
    rbbValue: "2624870450",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "8368573288"
  },
  {
    id : 11,
    code_component : 403, 
    rbbValue: "474265525",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "2368842870",
    lastMonthValue: "8368573288"
  },
  {
    id : 12,
    code_component : 409, 
    rbbValue: "  126581496",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "8368573288"
  },
  {
    id : 13,
    code_component : 501, 
    rbbValue: "550427376",
    realisasiValue: "527006117",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "5521431583"
  },
  {
    id : 14,
    code_component : 502, 
    rbbValue: "0",
    realisasiValue: "12035421",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "161211140"
  },
  {
    id : 15,
    code_component : 503, 
    rbbValue: "0",
    realisasiValue: "0",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "0"
  },
  {
    id : 16,
    code_component : 504, 
    rbbValue: "452049060",
    realisasiValue: "398627313",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "4874452530"
  },
  {
    id : 17,
    code_component : 509, 
    rbbValue: "272566374",
    realisasiValue: "272352647",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "2277636702"
  },
  {
    id : 18,
    code_component : 599, 
    rbbValue: "4950880",
    realisasiValue: "1500000",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "48360500"
  },
  {
    id : 19,
    code_component : 601, 
    rbbValue: "0",
    realisasiValue: "7925299",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "152128063"
  },
  {
    id : 20,
    code_component : 602, 
    rbbValue: "0",
    realisasiValue: "7925299",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "152128063"
  },
]

export const paramData = [
  {
    id: 0,
    code: "",
    name: "Aset",
    rbbValue: "32247203662",
    realisasiValue: "27993932143",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "20904999900",
    lastMonthValue: "32147203650"

  },
  {
    id: 1,
    name: "Pembayaran",
    rbbValue: "21677275620",
    realisasiValue: "17440933103",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "20904999900",
    lastMonthValue: "21577275608"
  },
  {
    id: 2,
    name: "Aset Antar Kantor (RAK Aktiva)",
    rbbValue: "8468573300",
    realisasiValue: "54061903942",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "8430858080",
    lastMonthValue: "8368573288"
  },
  {
    id: 3,
    name: "DPK-Tabungan",
    rbbValue: "15412268744",
    realisasiValue: "12212490021",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "12084989900",
    lastMonthValue: "15312268732"
  },
  {
    id: 4,
    name: "DPK-Deposito",
    rbbValue: "14798017891",
    realisasiValue: "14898017903",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "1400000000",
    lastMonthValue: "14798017891"
  },
  {
    id: 5,
    name: "ABP - Tabungan & Deposito",
    rbbValue: "0",
    realisasiValue: "0",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "50000000",
    lastMonthValue: "-100000012"

  },
  {
    id: 6,
    name: " Pembiayaan dari Bank lain",
    rbbValue: "0",
    realisasiValue: "0",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "0",
    lastMonthValue: "0"
  },
  {
    id: 7,
    name: "  Liabilitas Antar Kantor (RAK Pasiva)",
    rbbValue: "0",
    realisasiValue: "46103554328",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "1500000000",
    lastMonthValue: "-100000012"
  },
  {
    id: 8,
    name: "  Laba Th.Berjalan",
    rbbValue: "906736202",
    realisasiValue: "1360048017",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    lastYearValue: "1000000000",
    lastMonthValue: "806736190"
  },
  {
    id: 9,
    name: "Pendapatan dari Pembiayaan",
    rbbValue: "2624870450",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "pendapatan"
  },
  {
    id: 10,
    name: "Pendapatan RAK",
    rbbValue: "474265525",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "pendapatan"
  },
  {
    id: 11,
    name: "Pendapatan Lainnya",
    rbbValue: "  126581496",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "pendapatan"
  },
  {
    id: 12,
    name: "Bagihasil DPK",
    rbbValue: "810512217",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "biaya"
  },
  {
    id: 13,
    name: "Bagihasil Pinjaman Bank lain",
    rbbValue: "810512217",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "biaya"
  },
  {
    id: 14,
    name: "Bagihasil Pinjaman RAK",
    rbbValue: "810512217",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "biaya"
  },
  {
    id: 15,
    name: "Biaya Tenaga Kerja",
    rbbValue: "810512217",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "biaya"
  },
  {
    id: 16,
    name: "Biaya Operasional lain",
    rbbValue: "810512217",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "biaya"
  },
  {
    id: 17,
    name: "Biaya Non Operasional",
    rbbValue: "810512217",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "biaya"
  },
  {
    id: 17,
    name: "Jumlah Biaya",
    rbbValue: "810512217",
    realisasiValue: "3624870450",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "biaya"
  },
  {
    id: 18,
    name: "FDR-1 (Pemby/DPK)",
    rbbValue: "",
    realisasiValue: "",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "rasio"
  },
  {
    id: 19,
    name: "FDR-2 (Pemby/(DPK+ABP+RAK)",
    rbbValue: "",
    realisasiValue: "",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "rasio"
  },
  {
    id: 20,
    name: "Non Performing Financing (NPF)",
    rbbValue: "",
    realisasiValue: "",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "rasio"
  },
  {
    id: 21,
    name: "Produktifitas Pembiayaan",
    rbbValue: "",
    realisasiValue: "",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "rasio"
  },
  {
    id: 22,
    name: "Biaya Dana/Total Dana",
    rbbValue: "",
    realisasiValue: "",
    periodRbb: "September 2022",
    dateRealisasi: "3 September 2022",
    flag: "rasio"
  },

]

export const laporanKomisaris = [
  {
    id: 1,
    name: "Pendapatan Margin dan Bahas",
    lastYearValue: "27138933772",
    mutasiAwal: "2526195509",
    mutasiAkhir: "3504805205",
    akumulasi: "3504805205",
    date: "Januari 2022",
  },
  {
    id: 2,
    name: "Pendapatan Ops Lain",
    lastYearValue: "1009655806",
    mutasiAwal: "258444010",
    mutasiAkhir: "251043601",
    akumulasi: "251043601",
    date: "Januari 2022",
  },
  {
    id: 3,
    name: "Biaya Bahas",
    lastYearValue: "8525790074",
    mutasiAwal: "835787131",
    mutasiAkhir: "806473063",
    akumulasi: "806473063",
    date: "Januari 2022",
  },
  {
    id: 4,
    name: " Biaya Tenaga Kerja (Termasuk Bonus)",
    lastYearValue: "1009655806",
    mutasiAwal: "258444010",
    mutasiAkhir: "251043601",
    akumulasi: "251043601",
    date: "Januari 2022",
  },
  {
    id: 5,
    name: "Biaya Ops lain",
    lastYearValue: "1009655806",
    mutasiAwal: "258444010",
    mutasiAkhir: "251043601",
    akumulasi: "251043601",
    date: "Januari 2022",
  },
  {
    id: 6,
    name: "Pajak dan Zakat",
    lastYearValue: "1009655806",
    mutasiAwal: "258444010",
    mutasiAkhir: "251043601",
    akumulasi: "251043601",
    date: "Januari 2022",
  },
  {
    id: 7,
    name: "Laba Rugi (sesudah Pajak PPh 25 bulanan dan zakat, bonus )",
    lastYearValue: "1009655806",
    mutasiAwal: "258444010",
    mutasiAkhir: "251043601",
    akumulasi: "251043601",
    date: "Januari 2022",
  },
  {
    id: 8,
    name: "Kenaikan DPP",
    lastYearValue: "1009655806",
    mutasiAwal: "258444010",
    mutasiAkhir: "251043601",
    akumulasi: "251043601",
    date: "Januari 2022",
  },
  {
    id: 9,
    name: "Kenaikan Pembiayaan",
    lastYearValue: "1009655806",
    mutasiAwal: "258444010",
    mutasiAkhir: "251043601",
    akumulasi: "251043601",
    date: "Januari 2022",
  },
  {
    id: 10,
    name: "PEMBIAYAAN",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "saldo"
  },
  {
    id: 11,
    name: "TABUNGAN",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "saldo"
  },
  {
    id: 12,
    name: "DEPOSITO",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "saldo"
  },
  {
    id: 13,
    name: "DPF",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "saldo"
  },
  {
    id: 14,
    name: "Biaya Bahas DPK",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "saldo"
  },
  {
    id: 15,
    name: "Produktifitas Pembiayaan",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "saldo"
  },
  {
    id: 16,
    name: "CASH DAN BANK",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "saldo"
  },
  {
    id: 17,
    name: "Kas, Giro, Tabungan",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "rincian"
  },
  {
    id: 18,
    name: "Deposito",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "rincian"
  },
  {
    id: 19,
    name: "Produktifitas Kas dan Bank",
    lastYearValue: "189466374978",
    mutasiAwal: "538729800",
    mutasiAkhir: "-934126609",
    akumulasi: "188532248369",
    date: "Januari 2022",
    flag: "rincian"
  },







]

