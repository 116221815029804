import jwtDecode from 'jwt-decode'
import { notification as notifAntd } from 'antd'
const {REACT_APP_API_KEY} = process.env
let initialValue = {}

export function getUser() {
  return initialValue
}

export function setUser(params) {
  initialValue = params
}

export const notification = {
  success: (description) => {
    notifAntd.success({
      message: 'Success',
      description,
    });
  },
  error: (description) => {
    notifAntd.error({
      message: 'Failed',
      description,
    });
  }
}

export function mergeRecursive(obj1, obj2) {
  if (!obj1) return obj2;
  for (let p in obj2) {
    try {
      // Property in destination object set; update its value.
      if (obj2[p].constructor === Object) {
        obj1[p] = this.mergeRecursive(obj1[p], obj2[p]);
      } else {
        obj1[p] = obj2[p];
      }
    } catch (e) {
      // Property in destination object not set; create it and set its value.
      obj1[p] = obj2[p];
    }
  }
  return obj1;
}

export function merge(target, source){
  const newTarget = target || {}
  const newSource = source || {}
  // Iterate through `source` properties and if an `Object` set property to merge of `target` and `source` properties
  for (const key of Object.keys(newSource)) {
    try {
      if (newSource[key] instanceof Object) Object.assign(newSource[key], merge(newTarget[key], newSource[key]))
    } catch (e) {
      Object.assign(newSource[key], newTarget[key])
    }
  }

  // Join `target` and modified `source`
  Object.assign(newTarget || {}, newSource)
  return newTarget
}

export function mergeDeep(target, source) {
  const isObject = (obj) => obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach(key => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export function errorMessage(e) {
  const {response, message: messageE} = e
  let message
  try {
    if (response) {
      const {data} = response
      const {message:messageResponse} = data
      if (data && messageResponse) {
        message = messageResponse
      } else {
        message = 'Something wrong, please try again.'
      }
    } else {
      message = messageE
    }
  } catch (e) {
    message = 'Something wrong, please try again.'
  }

  return message
}
export function copyToClipboard(text) {
  var dummy = document.createElement("textarea");
  // to avoid breaking orgain page when copying more words
  // cant copy when adding below this code
  // dummy.style.display = 'none'
  document.body.appendChild(dummy);
  //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
  dummy.value = text;
  dummy.select();
  document.execCommand("copy");
  document.body.removeChild(dummy);
}
export function handleStatusName(value) {
  return value.replace('_',' ').replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function isAccessTokenExpire() {
  const initialAccess = localStorage.getItem("secret")
  if(!initialAccess) return true
  const decoded = jwtDecode(initialAccess)
  const { exp } = decoded
//   console.log("eee",exp)
  return exp < (Date.now() / 1000);
}

export function getUserAccessToken() {
  const initialAccess = localStorage.getItem("secret")
//   console.log("initialAccess", initialAccess)
  const decoded = jwtDecode(initialAccess)
  const { exp } = decoded
  if (!initialAccess || initialAccess === '') {
    return ''
  } else if (exp < (Date.now() / 1000)) {
    // if(alert("secret expire")) document.location = '/login';
    window.location = '/login'
    return ''
  } else {
    return initialAccess
  }
}

export function getAccessHeader() {
  const headers = {headers: { 'X-Api-Key' : `${REACT_APP_API_KEY}`,}}
  try {
    const accesstToken = getUserAccessToken()
    // console.log("secret",accesstToken)
    if (accesstToken === '') {
      return headers
    }
    return {
      headers: {
        // 'X-Api-Key' : `${REACT_APP_API_KEY}`,
        'Authorization': `Bearer ${accesstToken}`,
      }
    }
  } catch (e) {
    return headers
  }
}
export function getAccessHeaderBlob() {
  const headers = {headers: { 'X-Api-Key' : `${REACT_APP_API_KEY}`,}}
  try {
    const accesstToken = getUserAccessToken()
    if (accesstToken === '') {
      return headers
    }
    return {
      headers: {
        'X-Api-Key' : `${REACT_APP_API_KEY}`,
        'Authorization': `Bearer ${accesstToken}`,
      }
    }
  } catch (e) {
    return headers
  }
}
