// import "./styles.css";
import React from "react";
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart
} from "recharts";

const data = [
  {
    name: "2018",
    uv: 4000,
    pv: 60,
    amt: 40
  },
  {
    name: "2019",
    uv: 3000,
    pv: 100,
    amt: 120
  },
  {
    name: "2020",
    uv: 2000,
    pv: 390,
    amt: 500
  },
  {
    name: "2021",
    uv: 4000,
    pv: 300,
    amt: 280
  },
  
];

export default function PertumbuhanPembiayaan() {
  return (
    <div style={{ width: '100%', height: 300 }}>
    <ResponsiveContainer>
    <ComposedChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" name=""/>
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="pv"  name="Target" fill="#5995DF" />
      <Bar dataKey="amt"  fill="red" name="Realisasi" />
    </ComposedChart>
    </ResponsiveContainer>
    </div>
  );
}
