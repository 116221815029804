import { Col, Input, notification, Row, DatePicker, Button, AutoComplete } from 'antd'
import { GET_NERACA_FINANCE, IMPORT_FINANCE } from 'API/Params'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { notif } from 'util/notification'
import ListData from './ListData'
import * as XLSX from 'xlsx'
// const { Search } = Input
export default function Index() {
    const [data, setData] = useState([])
    const [totalPage, setTotalPage] = useState("100")
    const [page, setPage] = useState("1")
    const [limit, setLimit] = useState(100)
    const [date, setDate] = useState(new Date())
    const [totalRows, setTotalRows] = useState("")
    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [openDate, setOpenDate] = useState(false)


    const dateFormat = 'YYYY-MM-DD';
    useEffect(() => {
        getData(page, limit, moment(date).format('YYYY-MM-DD'))
    }, [])
    const changeDate = (_date, dateString) => {
        // const date_ = dateString
        // setDate(date_)
     
        if (dateString !== "") {
            setDate(dateString)
            setOpenDate(false)
            getData("1", limit, dateString)
        } else {
            setOpenDate(true)
        }
    }
    const getData = async (_page, _size, query) => {
        setLoading(true)
        const param = `?page=${1}&limit=${120000}&date=${query}`
        // console.log("oke",param)
        try {
            const { data } = await GET_NERACA_FINANCE(param)
            console.log("okee", data.data)
            setData(data.data)
            setTotalPage(data.paginator.totalPages)
            setPage(data.paginator.page)
            // setLimit(data.data.length > 0 && data.paginator.limit)
            setTotalRows(data.paginator.total)
        } catch (e) {
            notification.error("Gagal Mengambil Data!")
        } finally {
            setLoading(false)
        }
    }
    const onPaginationChange = (page, size) => {
        getData(page, size, date)
        setPage(page)
        setLimit(size)
    }
    const downloadExcel = (data) => {
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        // const max_width = rows.reduce((w, r) => Math.max(w, r.name.length), 10);
        // worksheet["!cols"] = [{ wch: max_width }];
        XLSX.utils.book_append_sheet(workbook, worksheet, `Neraca-IBA-${moment(date).format(dateFormat)}`);
        XLSX.writeFile(workbook, `Neraca-IBA-${moment(date).format(dateFormat)}.xlsx`);
    }
    const exportNeraca = async () => {
        downloadExcel(data)
    }
  
    const importNeraca = async () => {
        setLoading(true)
        try {
            const { data } = await IMPORT_FINANCE()
            if (data.status) {
                notif("success", "Sukses!", "Data Neraca Berhasil di Import")
            } else {
                notif("error", "Gagal!", "Terjadi Kesalahan")
            }
        } catch (e) {
            notif("error", "Gagal!", "Terjadi Kesalahan!")
        } finally { setLoading(false) }
    }
    const handleSearch = e => {
        setSearchValue(e)
    };
    return (
        <div>
            <Row gutter={[8, 8]} className="gx-p-2">
                <Col xl={24} lg={24} md={24} sm={24}>
                    <AutoComplete
                        style={{
                            width: "100%",
                        }}
                        onSearch={handleSearch}
                    >
                        <Input.Search placeholder="Cari No. SBB, Keterangan, KantorCabang .." enterButton />
                    </AutoComplete>
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} >
                    <DatePicker style={{ width: "100%" }} format={dateFormat} onChange={changeDate} value={moment(date, dateFormat)} open={openDate} onClick={() => setOpenDate(true)} />
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} >
                    <Button type="primary" ghost style={{ width: "100%" }} onClick={() => importNeraca()}>Import Neraca</Button>
                </Col>
                <Col xl={8} lg={8} md={8} sm={24} >
                    <Button type="primary" style={{ width: "100%" }} onClick={() => exportNeraca()}>Export Neraca</Button>
                </Col>
            </Row>
            <ListData data={data} total={totalPage} limit={limit} onPaginationChange={onPaginationChange} totalRow={totalRows} page={page} loading={loading} refreshData={getData} searchValue={searchValue} />
        </div>
    )
}
