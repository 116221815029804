import React from "react";
import {Route, Switch} from "react-router-dom";
import KinerjaKeuangan from "pages/UserKomisaris/Index";

const UserKomisaris = ({match}) => (
  <Switch>
    <Route path={`${match.url}/kinerja-keuangan`} component={KinerjaKeuangan}/>
  </Switch>
);

export default UserKomisaris;
