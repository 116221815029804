import { Card, Col, Row } from 'antd'
import React from 'react'
import Aset from './Aset'
import KomposisiTabungan from './KomposisiTabungan'
import PertumbuhanPembiayaan from './PertumbuhanPembiayaan'
import PihakKetiga from './PihakKetiga'

export default function Index() {
    return (
        <div className="gx-p-2">
            <Row>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Card className="gx-card" title="Pertumbuhan Aset">
                        <Aset />
                    </Card>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Card className="gx-card" title="Komposisi Dana Pihak Ketiga">
                        <PihakKetiga />
                    </Card>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Card className="gx-card" title="Pertumbuhan Pembiayaan">
                        <PertumbuhanPembiayaan />
                    </Card>
                </Col>
                <Col xl={12} lg={12} md={24} sm={24} xs={24}>
                    <Card className="gx-card" title="Komposisi Tabungan Menurut Interval Saldo">
                        <KomposisiTabungan />
                    </Card>
                </Col>
            </Row>

        </div>
    )
}
