import {
    GET_USERS,
    GET_USERS_DONE  } from "../../constants/ActionTypes";
  
  const INIT_STATE = {
    usersList: []
  };
  
  
  const UsersReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
  
      case GET_USERS: {
        return {
          ...state,
          usersList: action.usersList,
        }
      }
  
      case GET_USERS_DONE: {
        return {
          ...state,
          usersList: action.payload,
        }
      }
  
    //   case UPDATE_ALL_NOTES_SUCCESS: {
    //     return {
    //       ...state,
    //       notesList: action.payload,
    //     }
    //   }
  
    //   case NOTES_UPDATE:
    //     return {
    //       ...state,
    //       notesList: action.notesList,
    //     };
  
      default:
        return state;
    }
  }
  
  export default UsersReducer;
  