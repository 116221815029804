import { delete_request, get, post, put } from "util/HttpRequest"
export const GET_GLOBAL_PARAMS = async () => {
    return await get({url : `/parameters` })
}
export const IMPORT_FINANCE = async () => {
    return await get({url : `/finance/params/import` })
}
export const GET_PARAM_FINANCE = async (query) => {
    return await get({url : `/finance/params${query}` })
}
export const GET_NERACA_FINANCE = async (query) => {
    return await get({url : `/finance/neraca${query}` })
}
export const UPDATE_PARAM_FINANCE = async (query, data) => {
    return await put({url : `/finance/params/${query}`, data : data})
}
export const GET_PARAM_GROUP_KOMPONEN = async () => {
    return await get({url : `/finance/groups`})
}
export const POST_PARAM_GROUP_KOMPONEN = async (data) => {
    return await post({url : `/finance/groups`, data : data})
}
export const DELETE_PARAM_GROUP_KOMPONEN = async (data) => {
    return await delete_request({url : `/finance/groups/${data}`})
}
export const UPDATE_PARAM_GROUP_KOMPONEN = async (id, data) => {
    return await put({url : `/finance/groups/${id}`, data : data})
}

export const GET_PARAM_KOMPONEN = async (param) => {
    return await get({url : `/finance/components${param}`})
}
export const POST_PARAM_KOMPONEN = async (data) => {
    return await post({url : `/finance/components`, data : data})
}
export const DELETE_PARAM_KOMPONEN = async (data) => {
    return await delete_request({url : `/finance/components/${data}`})
}
export const UPDATE_PARAM_KOMPONEN = async (id, data) => {
    return await put({url : `/finance/components/${id}`, data : data})
}
export const UPLOAD_RBB = async (data) => {
    return await post({url : `/finance/rbb`, data : data})
}
export const GET_RBB = async (data) => {
    return await get({url : `/finance/rbb${data}`})
}