import { getUser } from "util/DataUser";
const AppsNavigation = () => (
  <div className="gx-flex-row gx-align-items-center gx-mb-1">
    <span className="gx-avatar-name gx-ml-1">{getUser().jabatan}</span>
    {/* <hr/> */}
    <span className="gx-avatar-name gx-ml-1">{getUser().kantorCabang}</span>
    {/* <span>{getUser().level}</span> */}
  </div>
);

export default AppsNavigation;
