import React from 'react'
import {
    ResponsiveContainer,
    ComposedChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
  } from 'recharts';
  
  const data = [
    {
      name: '2017',
      uv: 590,
      aset: 800,
    },
    {
      name: '2018',
      uv: 868,
      aset: 967
    },
    {
      name: '2019',
      uv: 1397,
      aset: 1098,
    },
    {
      name: '2020',
      uv: 1480,
      aset: 1200,
    },
    {
      name: '2021',
      uv: 1520,
      aset: 1108,
    },
  ];
export default function Aset() {
  return (
    <div style={{ width: '100%', height: 300 }}>
    <ResponsiveContainer>
      <ComposedChart
        width={500}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey="name" scale="band" name="Dalam 9" />
        <YAxis />
        <Tooltip />
        <Legend />
        {/* <Area type="monotone" dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
        <Bar dataKey="aset" name={"Aset"} barSize={20} fill="#413ea0" />
        {/* <Line type="monotone" dataKey="uv" stroke="#ff7300" /> */}
      </ComposedChart>
    </ResponsiveContainer>
  </div>
  )
}
