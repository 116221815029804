import React, {} from 'react'

import UserPinca from './UserPinca'

export default function index() {

  return (
    <div>
        <UserPinca/>
    </div>
  )
}
