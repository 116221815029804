import { Card, Row, Col, Select, DatePicker, Spin, Space, Tabs } from "antd";
import React, { useState, useEffect } from "react";
import KinerjaKeuangan from "./KinerjaKeuangan";
import { setTitle } from "util/functions";
import { GET_GLOBAL_PARAMS } from "API/Params";
import moment from "moment";
import { notif } from "util/notification";
import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import Komitmen from "./Komitmen";
// import {onNavStyleChange} from "appRedux/actions/Setting";
import { useDispatch } from "react-redux";
// import { getUser } from 'util/DataUser';
export default function Index() {
  setTitle("Laporan Keuangan");
  // const dispatch = useDispatch();
  let dateNow = new Date();
  dateNow.setDate(dateNow.getDate() - 1);
  const [kodeKantor, setKodeKantor] = useState("00");
  const [kodeCabang, setKodeCabang] = useState("00");
  const [namaKantor, setNamaKantor] = useState("Gabungan");
  const [kantorCabang, setKantorCabang] = useState([]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dateNow);
  const [openDate, setOpenDate] = useState(false);
  const [key, setKey] = useState("1");
  const dateFormat = "YYYY-MM-DD";

  const changeDate = (_date, dateString) => {
    if (dateString !== "") {
      setDate(dateString);
      setOpenDate(false);
    } else {
      setOpenDate(true);
    }
  };

  useEffect(() => {
    let a = '{"kode":"00"}|{"kodeCabang":"00"}|{"name":"Gabungan"}';
    handleSelectKantor(a);
    getParams();
  }, []);
  const getParams = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await GET_GLOBAL_PARAMS();
      // console.log("d", data.kantorCabang.value)
      let paramGabungan = {
        kode: "00",
        kodeCabang: "00",
        name: "Gabungan",
      };
      let mergeAll = [...data.kantorCabang.value, paramGabungan];
      mergeAll.sort((a, b) => Number(a.kode) - Number(b.kode));
      // console.log("mergeAll", mergeAll)

      setKantorCabang(mergeAll);
    } catch (e) {
      notif("error", "Gagal!", "Gagal dalam mengambil data parameter kantors!");
    } finally {
      setLoading(false);
    }
  };
  const changeTab = (key) => {
    console.log(key);
    setKey(key);
    // console.log("User",user.kantor)
  };
  const handleSelectKantor = (val) => {
    // console.log("val",val)
    let kodeKantor = [];
    let kodeCabang = [];
    let namaKantor = [];
    const item = val.split("|");
    kodeKantor.push(JSON.parse(item[0]));
    kodeCabang.push(JSON.parse(item[1]));
    namaKantor.push(JSON.parse(item[2]));
    setKodeKantor(kodeKantor[0].kode);
    setKodeCabang(kodeCabang[0].kodeCabang);
    setNamaKantor(namaKantor[0].name);
    // setTitle(`Lap. ${namaKantor[0].name}`)
  };
  const items1 = [
    {
      label: (
        <span>
          <BarChartOutlined /> Kinerja Keuangan{" "}
        </span>
      ),
      key: "1",
      children: (
        <KinerjaKeuangan
          kodeKantor={kodeKantor}
          date={date}
          kodeCabang={kodeCabang}
          changeTab={changeTab}
        />
      ),
    },
  ];
  const items = [
    {
      label: (
        <span>
          <BarChartOutlined /> Kinerja Keuangan{" "}
        </span>
      ),
      key: "1",
      children: (
        <KinerjaKeuangan
          kodeKantor={kodeKantor}
          date={date}
          kodeCabang={kodeCabang}
          changeTab={changeTab}
        />
      ),
    },
    {
      label: (
        <span>
          <AuditOutlined /> Saran & Komitmen
        </span>
      ),
      key: "2",
      children: (
        <Komitmen date={date} kodeKantor={kodeKantor} namaKantor={namaKantor} />
      ),
    },
  ];
  // console.log("kantorcabang", kodeCabang, kodeKantor)
  return (
    <div>
      <Card className="gx-card" style={{marginBottom : 10}}>
        <Spin spinning={loading}>
          <Row gutter={[8, 8]} >
            <Col span={12}>
              <Space>
                <ClusterOutlined className="gx-mb-3" />
                <p>Pilih Kantor</p>
              </Space>

              <Select
                placeholder="Gabungan"
                style={{ width: "100%" }}
                showSearch
                onChange={(value) => handleSelectKantor(value)}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {kantorCabang.map((e, i) => {
                  return (
                    <Select.Option
                      placeholder="Pilih Kantor.."
                      key={i}
                      defaultValue={'{"kode":"01"}|{"kodeCabang":"001"}'}
                      value={
                        JSON.stringify({ kode: e.kode }) +
                        "|" +
                        JSON.stringify({ kodeCabang: e.kodeCabang }) +
                        "|" +
                        JSON.stringify({ name: e.name })
                      }
                    >
                      {e.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Col>
            <Col span={12}>
              <Space>
                <CalendarOutlined className="gx-mb-3" />
                <p>Pilih Tanggal</p>
              </Space>
              <DatePicker
                style={{ width: "100%" }}
                format={dateFormat}
                onChange={changeDate}
                value={moment(date, dateFormat)}
                open={openDate}
                onClick={() => setOpenDate(true)}
              />
            </Col>
          </Row>
        </Spin>
      </Card>
      <Tabs
        defaultActiveKey="1"
        onChange={changeTab}
        items={kodeKantor === "00" ? items1 : items}
        className="gx-p-2"
        activeKey={key}
      />
    </div>
  );
}
