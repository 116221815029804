import { Button, Card, Col, Input, Row, Table } from 'antd'
import React, { useEffect } from 'react'
import { getUser } from 'util/DataUser'
import { currFormat } from 'util/functions'

export default function Preview({ component, year,handleSend}) {
    // console.log("Komp", component)
    useEffect(() => {
        component.sort((a, b) => a.nourut - b.nourut)
    },[component])
    const col = [
        { title: "Kode", dataIndex: "code", width: 70 },
        { title: "Uraian", dataIndex: "name", width: 200 },
        { title: `Jan-${year}`, dataIndex: `Jan`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Feb-${year}`, dataIndex: `Feb`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Mar-${year}`, dataIndex: `Mar`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Apr-${year}`, dataIndex: `Apr`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Mei-${year}`, dataIndex: `May`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Jun-${year}`, dataIndex: `Jun`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Jul-${year}`, dataIndex: `Jul`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Aug-${year}`, dataIndex: `Aug`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Sep-${year}`, dataIndex: `Sep`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Okt-${year}`, dataIndex: `Oct`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Nov-${year}`, dataIndex: `Nov`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
        { title: `Des-${year}`, dataIndex: `Desc`, render: (text, record) => record.group === "Rasio" ? <Input size="small" suffix="%" value={currFormat(text)} /> : <Input size="small" prefix="Rp" value={currFormat(text)} /> , width: 150 },
    ]
    return (
        <div>
            <Card>
                <Row>
                    <Col span={12} className="gx-text-left">
                    {/* <h4>Preview Data</h4> */}
                    <h4>Preview RBB Tahun : {year}</h4>
                    <h4> {getUser().level === "Admin" ? "Gabungan" : getUser().kantorCabang}</h4>
                    </Col>
                    <Col span={12} className="gx-text-right">
                    <Button className="gx-btn-success" onClick={() => handleSend()}>Upload Data RBB</Button>
                    </Col>
                </Row>
            </Card>
                <Table dataSource={component}columns={col} size="small" pagination={false} scroll={{ y: 1080 }} bordered rowKey={"id"} />
        </div>
    )
}
