/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  ExclamationCircleOutlined,
  PlusCircleFilled,
  UserOutlined,
} from "@ant-design/icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  DELETE_MESSAGE,
  EDIT_MESSAGE,
  GET_MESSAGE,
  POST_MESSAGE,
} from "API/API";
import { Alert, Button, Card, Input, Modal, Row, Spin, Timeline } from "antd";
import Col from "antd/es/grid/col";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getUser } from "util/DataUser";
import { notif } from "util/notification";

export default function Komitmen({ date, kodeKantor, namaKantor }) {
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [id, setId] = useState("");
  const [visible, setVisible] = useState(false);
  const [colRealisasi, setColRealisasi] = useState("");
  const [description, setDescription] = useState("");
  const [listKomitmen, setListKomitmen] = useState([]);

  const getMessage = useCallback(async () => {
    try {
      const { data } = await GET_MESSAGE(
        `?sort=-createdAt&query[kantor]=${kodeKantor}`
      );
      setListKomitmen(data.docs);
      // console.log("pk", data.docs);
    } catch (e) {
      Alert.error("Gagal mendapatkan data komitmen");
    }
  }, [kodeKantor]);

  useEffect(() => {
    setColRealisasi(moment(moment(date, "YYYY-MM-DD")).format("DD MMMM YYYY"));
    getMessage();
  }, [date, getMessage]);

  const editMessage = useCallback((e) => {
    setIsEdit(true);
    setVisible(true);
    setId(e.id);
    // setKomitmens([e])
    setDescription(e.description);
  }, []);

  const deleteItem = useCallback(
    async (data) => {
      try {
        DELETE_MESSAGE(data.id);
        notif("success", "Sukses!", "Komitmen Berhasil Terhapus!");
        getMessage();
      } catch (e) {
        notif("error", "Error!", "Komitmen Gagal Terhapus!");
      }
    },
    [getMessage]
  );

  const deleteMessage = useCallback(
    (data) => {
      Modal.confirm({
        title: "Konfirmasi",
        icon: <ExclamationCircleOutlined />,
        content: `Apakah anda yakin untuk menghapus ? Komitmen akan terhapus secara permanen.`,
        okText: "Hapus",
        cancelText: "Tutup",
        onOk: () => deleteItem(data),
      });
    },
    [deleteItem]
  );

  const getExtra = useCallback(
    (e) => {
      if (e.user.name === getUser().name) {
        return (
          <>
            <a href="#" className="gx-mr-2" onClick={() => editMessage(e)}>
              Edit
            </a>
            <a
              href="#"
              className="gx-text-danger"
              onClick={() => deleteMessage(e)}
            >
              Hapus
            </a>
          </>
        );
      }
    },
    [deleteMessage, editMessage]
  );

  const disable = useMemo(
    () => (description === "" ? true : false),
    [description]
  );

  const title = useMemo(
    () =>
      getUser()?.level === "PE Audit Internal"
        ? "Tambahkan Tanggapan Audit"
        : `Saran Direksi untuk ${namaKantor}`,
    [namaKantor]
  );

  const handleEditorReady = useCallback((editor) => {
    console.log("Editor is ready to use!", editor);
  }, []);

  const handleEditorDataChange = useCallback((event, editor) => {
    console.log("handleEditorDataChange");
    setDescription(editor.getData());
  }, []);

  const handleSave = useCallback(async () => {
    console.log("ckeditor data", description);
    setLoading(true);
    let data = {
      description: description,
      kantor: kodeKantor,
      tglLaporan: moment(moment(date, "YYYY-MM-DD")).format("YYYY-MM-DD"),
    };
    try {
      if (isEdit) {
        await EDIT_MESSAGE(id, data);
      } else {
        await POST_MESSAGE(data);
      }
      notif("success", "Sukses!", "Data berhasil disimpan.");
      setVisible(false);
      getMessage();
    } catch (e) {
      notif("error", "Error!", "Data gagal disimpan!");
    } finally {
      setLoading(false);
    }
  }, [date, description, getMessage, id, isEdit, kodeKantor]);

  return (
    <div>
      <Button
        type="primary"
        onClick={() => {
          setVisible(true);
          setIsEdit(false);
          setDescription("");
          setId("");
        }}
        icon={<PlusCircleFilled />}
      >
        {getUser()?.level === "PE Audit Internal"
          ? "Beri Tanggapan"
          : "Tambahkan Saran"}
      </Button>
      <div className="gx-card gx-p-3">
        {listKomitmen.length === 0 ? (
          <>Tidak ada Komitmen</>
        ) : (
          <div>
            <Timeline mode="left">
              {listKomitmen.map((e, i) => {
                return (
                  <div key={e?._id}>
                    <Timeline.Item
                      color={
                        e.user.jabatan?.split(" ")?.shift() === "Direktur"
                          ? "green"
                          : "blue"
                      }
                    >
                      <Card
                        size="small"
                        title={
                          <span>
                            <UserOutlined
                              className={
                                e.user.jabatan?.split(" ")?.shift() ===
                                "Direktur"
                                  ? "gx-text-success"
                                  : e.user.jabatan?.split(" ")?.shift() ===
                                    "Komisaris"
                                  ? "gx-text-danger"
                                  : "gx-text-primary"
                              }
                            />{" "}
                            {e.user.name} - {e.user.jabatan} -{" "}
                            {moment(moment(e.tglLaporan, "YYYY-MM-DD")).format(
                              "DD-MMM-YYYY"
                            )}
                          </span>
                        }
                        extra={getExtra(e)}
                      >
                        {e.commit && <p>Komitmen : {e.commit}</p>}
                        {/* <p>{e.description}</p> */}
                        <div
                          dangerouslySetInnerHTML={{ __html: e.description }}
                        />
                      </Card>
                    </Timeline.Item>
                  </div>
                );
              })}
            </Timeline>
          </div>
        )}
      </div>

      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        title={title}
        footer={null}
        width={1000}
      >
        <Spin spinning={loading} size="large">
          <p>Tanggapan (Wajib diisi)</p>

          <CKEditor
            editor={ClassicEditor}
            data={description}
            onReady={handleEditorReady}
            onChange={handleEditorDataChange}
          />

          <p>Tanggal. Laporan</p>
          <Input value={colRealisasi} disabled={true} />

          <hr />
          <Row>
            <Col span={12}>
              <Button
                className="gx-btn-block"
                type="danger"
                ghost
                onClick={() => setVisible(false)}
              >
                Tutup
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="gx-btn-block"
                type="primary"
                onClick={() => handleSave()}
                disabled={disable}
              >
                Simpan
              </Button>
            </Col>
          </Row>
        </Spin>
      </Modal>
    </div>
  );
}
