import { delete_request, get, post, put } from "util/HttpRequest"

export const GET_LAPORAN_FINANCE = async (query) => {
    return await get({url : `/finance/perfomance${query}` })
}
export const POST_MESSAGE = async (query) => {
    return await post({url : `/finance/message`, data : query})
}
export const EDIT_MESSAGE = async (id, query) => {
    return await put({url : `/finance/message/${id}`, data : query})
}
export const GET_MESSAGE = async (query) => {
    return await get({url : `/finance/message/${query}`})
}
export const DELETE_MESSAGE = async (query) => {
    return await delete_request({url : `/finance/message/${query}`})
}