import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Button, Modal, Space, Table, Form, Input, Divider, Row, Col, Select } from 'antd'
import { groupLaporan, paramLaporanDewan } from 'dummy/parameter'
import React, { useState } from 'react'
// import GroupLaporan from './GroupLaporan'

const FormItem = Form.Item
const label = {
    labelCol: {
        span: 24,
    },
    wrapperCol: {
        span: 24,
    },
};


export default function LaporanKomisaris() {
    const [visible, setVisible] = useState(false)
    const [datas, setDatas] = useState({})
    const [titleModal, setTitleModal] = useState("Komponen Baru")
    const [form] = Form.useForm();
    // let titleModal = "Cabang Baru"

    const newCabang = () => {
        form.resetFields()
        setVisible(true)
        setTitleModal("Komponen Baru")
        setDatas({})
    }
    const confirm = (data) => {
        Modal.confirm({
            title: 'Konfirmasi',
            icon: <ExclamationCircleOutlined />,
            content: `Apakah anda yakin untuk menghapus ${data.Komponen} ? Data akan terhapus secara permanen.`,
            okText: 'Hapus',
            cancelText: 'Tutup',
        });
    };


    const handleActiion = (record) => {
        return <Space><EditOutlined className="gx-text-primary" style={{ cursor: 'pointer' }} onClick={() => { setDatas(record); setVisible(true); setTitleModal("Edit Komponen") }} /> <DeleteOutlined className="gx-text-danger" onClick={() => confirm(record)} /></Space>
    }
    const columns = [
        {
            title: "No. Urut",
            dataIndex: "noUrut",
            width: 200
        },
        {
            title: "Kode Komponen",
            dataIndex: "kode",
            width: 200
        },
        {
            title: "Nama Komponen",
            dataIndex: "komponen",
            width: 200
        },
        {
            title: "Group Komponen",
            dataIndex: "group",
            width: 200
        },
        {
            title: "Aksi",
            width: 100,
            render: (record) => handleActiion(record),
            align: "right"
        }
    ]
    const onFinish = (value) => {
        console.log("finish", value)
    }


    return (
        <>
            <Row gutter={[8, 8]}>
                <Col lg={18} md={18} sm={12} xs={12}>
                    <Input.Search placeholder="Cari Nama Komponen.." />
                </Col>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <Button className="gx-btn-block" type="primary" icon={<PlusCircleFilled />} onClick={() => newCabang()}>Komponen Baru</Button>
                </Col>
            </Row>

            <div className="gx-module-box-column">
                <Table dataSource={paramLaporanDewan} columns={columns} rowKey="id" rowClassName={(_record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'} scroll={{ x: 500 }} />
                <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
                    <h3>{titleModal}</h3>
                    <Divider />
                    <Form
                        {...label}
                        name="control-hooks"
                        form={form}
                        onFinish={onFinish}
                        fields={[
                            {
                                name: ["noUrut"],
                                value: datas.noUrut
                            },
                            {
                                name: ["kode"],
                                value: datas.Kode
                            },
                            {
                                name: ["komponen"],
                                value: datas.Komponen
                            },
                            {
                                name: ["group"],
                                value: datas.id_group
                            },
                            {
                                name: ["visibility"],
                                value: datas.visibility
                            },
                        ]}>
                        <FormItem name="noUrut" rules={[{ required: true }]} label="No. Urut" >
                            <Input />
                        </FormItem>
                        <FormItem name="kode" rules={[{ required: true }]} label="Kode" >
                            <Input />
                        </FormItem>
                        <FormItem name="komponen" rules={[{ required: true }]} label="Nama Komponen" >
                            <Input />
                        </FormItem>
                        <FormItem name="group" rules={[{ required: true }]} label="Group" >
                            <Select>
                                {groupLaporan.map((e, i) => {
                                    return <Select.Option value={e.id} key={i}>{e.name}</Select.Option>
                                })}
                            </Select>
                        </FormItem>
                        <FormItem name="visibility" rules={[{ required: true }]} label="Visibility (Berdasarakn Tampilan User)" >
                            <Select mode={"tags"}>
                                <Select.Option value="PINCA">PINCA</Select.Option>
                                <Select.Option value="DIREKSI">DIREKSI</Select.Option>
                            </Select>
                        </FormItem>
                        <div className="gx-text-right">
                            <Button htmlType="submit" type="primary">Simpan</Button>
                        </div>
                    </Form>
                </Modal>
            </div>
        </>

    )
}
