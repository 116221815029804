export const menuAdmin = [
    {
        type: 'group',
        label: "Dashboard",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                ico: "icon icon-dasbhoard",
                key: "dashboard"
            }
        ]
    },
    {
        type: 'group',
        label: "Kinerja Keuangan",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/user-pinca/kinerja-keuangan",
                name: "Laporan (PINCA)",
                ico: "icon icon-data-display",
                key: "user-pinca/kinerja-keuangan"
            },
            {
                path: "/user-direksi/kinerja-keuangan",
                name: "Laporan (DIREKSI)",
                ico: "icon icon-data-display",
                key: "user-direksi/kinerja-keuangan"
            },
            {
                path: "/user-komisaris/kinerja-keuangan",
                name: "Laporan (KOMISARIS)",
                ico: "icon icon-data-display",
                key: "user-komisaris/kinerja-keuangan"
            }
        ]
    },
    {
        type: 'group',
        label: "Config",
        className: 'gx-menu-group',
        key: "config",
        children: [
            {
                path: "/config/upload-rbb",
                name: "Upload RBB",
                ico: "icon icon-upload",
                key: "config/upload-rbb"
            },
            {
                path: "/config/parameter",
                name: "Parameter",
                ico: "icon icon-crm",
                key: "config/parameter"
            },
            {
                path: "/config/users",
                name: "Users",
                ico: "icon icon-user-o",
                key: "config/users"
            },
        ]
    }
]
export const menuPinca = [
    {
        type: 'group',
        label: "Dashboard",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                ico: "icon icon-dasbhoard",
                key: "dashboard"
            }
        ]
    },
    {
        type: 'group',
        label: "Kinerja Keuangan",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/user-pinca/kinerja-keuangan",
                name: "Laporan Keuangan",
                ico: "icon icon-data-display",
                key: "user-pinca/kinerja-keuangan"
            }
        ]
    },
    {
        type: 'group',
        label: "Config",
        className: 'gx-menu-group',
        key: "config",
        children: [
            {
                path: "/config/upload-rbb",
                name: "Upload RBB",
                ico: "icon icon-upload",
                key: "config/upload-rbb"
            },
        ]
    }
]
export const menuDireksi = [
    {
        type: 'group',
        label: "Dashboard",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                ico: "icon icon-dasbhoard",
                key: "dashboard"
            }
        ]
    },
    {
        type: 'group',
        label: "Kinerja Keuangan",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/user-direksi/kinerja-keuangan",
                name: "Laporan Keuangan",
                ico: "icon icon-data-display",
                key: "user-direksi/kinerja-keuangan"
            },

        ]
    },

]
export const menuAudit = [
    {
        type: 'group',
        label: "Dashboard",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                ico: "icon icon-dasbhoard",
                key: "dashboard"
            }
        ]
    },
    {
        type: 'group',
        label: "Kinerja Keuangan",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/user-direksi/kinerja-keuangan",
                name: "Laporan Keuangan",
                ico: "icon icon-data-display",
                key: "user-direksi/kinerja-keuangan"
            },

        ]
    },

]
export const menuKomisaris = [
    {
        type: 'group',
        label: "Dashboard",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/dashboard",
                name: "Dashboard",
                ico: "icon icon-dasbhoard",
                key: "dashboard"
            }
        ]
    },
    {
        type: 'group',
        label: "Kinerja Keuangan",
        className: 'gx-menu-group',
        key: "kinerjaKeuangan",
        children: [
            {
                path: "/user-komisaris/kinerja-keuangan",
                name: "Laporan Keuangan",
                ico: "icon icon-data-display",
                key: "user-komisaris/kinerja-keuangan"
            },

        ]
    },

]